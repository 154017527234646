import { Group } from './group.model'
import { User } from './user.model'

export class Conversation {
	public id: string

	public group: Group
	public participants: User[]
	public candidate: User

	public isLocked: boolean
	public hasSeen: string[]
	public messages: any[]
	public status: string
	public updatedAt: Date
	public folderShared: boolean
	public name: string
	public whoDontSee: any[]
	public hasSetupIntents: boolean
	private _json: any
	public invisibleFor = []

	public seenByUser: boolean

	public application: {
		startDate: Date
		duration: string
		status: string
		date: Date
	}

	public toJSON() {
		return this._json
	}

	public constructor(conversation, deep = true) {
		const {
			_id = null,
			name = null,
			group = null,
			participants = null,
			candidate = null,
			isLocked = null,
			hasSeen = null,
			messages = null,
			status = null,
			updatedAt = null,
			folderShared = null,
			whoDontSee = null,
			hasSetupIntents = null,
			invisibleFor = null,
			application = null,
		} = conversation

		this._json = conversation

		this.id = _id
		this.isLocked = isLocked
		this.hasSeen = hasSeen && [...hasSeen]
		this.messages = messages && [...messages]
		this.status = status
		this.updatedAt = updatedAt && new Date(updatedAt)
		this.folderShared = folderShared
		this.name = name
		this.whoDontSee = whoDontSee
		this.hasSetupIntents = hasSetupIntents
		this.invisibleFor = invisibleFor
		this.application = application

		if (deep) {
			this.group = group && new Group(group, false)
			this.participants =
				participants && participants.map((p) => new User(p, false))
			this.candidate = candidate && new User(candidate, false)
		} else {
			this.group = group && { ...group }
			this.participants = participants && [...participants]
			this.candidate = candidate
		}
	}
}
