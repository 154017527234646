import { CommonModule } from '@angular/common'
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core'
import { isPlatformBrowser } from '@angular/common'
import { TranslateModule } from '@ngx-translate/core'
import { RouterModule } from '@angular/router'

@Component({
	selector: 'app-cookies-alert',
	standalone: true,
	imports: [CommonModule, TranslateModule, RouterModule],
	templateUrl: './cookies-alert.component.html',
	styleUrls: ['./cookies-alert.component.scss'],
})
export class CookiesAlertComponent implements OnInit {
	public show = false
	public isBrowser: boolean
	lang

	constructor(@Inject(PLATFORM_ID) private platformId: object) {}

	ngOnInit() {
		if (isPlatformBrowser(this.platformId)) {
			this.show = !localStorage.getItem('acceptCookies')
			this.lang =
				localStorage.getItem('lang') === 'fr'
					? '/'
					: '/' + localStorage.getItem('lang')
		}
	}

	public closeAlert(): void {
		localStorage.setItem('acceptCookies', 'true')
		this.show = false
	}
}
