import { Component, EventEmitter, Output } from '@angular/core'
import { NgxSmartModalService } from 'ngx-smart-modal'

@Component({
	selector: 'app-reactivate-profile-modal',
	templateUrl: './reactivate-profile-modal.component.html',
	styleUrls: ['./reactivate-profile-modal.component.scss'],
})
export class ReactivateProfileModalComponent {
	@Output() reactive: EventEmitter<any> = new EventEmitter<any>()

	constructor(public ngxSmartModalService: NgxSmartModalService) {}

	reactiver() {
		this.reactive.emit()
	}
}
