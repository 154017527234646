import { Inject, Injectable, PLATFORM_ID } from '@angular/core'
import { environment } from 'src/environments/environment'
import { BehaviorSubject } from 'rxjs'
import { Loader } from '@googlemaps/js-api-loader'
import { isPlatformBrowser } from '@angular/common'

interface Scripts {
	name: string
	src: string
	id?: string
}

export const ScriptStore: Scripts[] = [
	{ name: 'stripeV3', src: 'https://js.stripe.com/v3/' },
	{
		name: 'GoogleMapAPI',
		src: 'https://maps.googleapis.com/maps/api/mapsjs',
	},
	{
		name: 'FacebookConnect',
		src: 'https://connect.facebook.net/en_US/sdk.js',
		id: 'facebook-jssdk',
	},
	{
		name: 'Tawk',
		src: 'https://embed.tawk.to/5978a8970d1bb37f1f7a5f35/default',
	},
	{
		name: 'GoogleTag',
		src: 'https://www.googletagmanager.com/gtag/js?id=UA-48632830-1',
	},
	{
		name: 'Calendly',
		src: 'https://assets.calendly.com/assets/external/widget.js',
	},
]

export const ScriptStoreDev: Scripts[] = [
	{ name: 'stripeV3', src: 'https://js.stripe.com/v3/' },
	{
		name: 'GoogleMapAPI',
		src: 'https://maps.googleapis.com/maps/api/mapsjs',
	},
	{
		name: 'FacebookConnect',
		src: 'https://connect.facebook.net/en_US/sdk.js',
		id: 'facebook-jssdk',
	},
	{
		name: 'Calendly',
		src: 'https://assets.calendly.com/assets/external/widget.js',
	},
]

declare let document: any

@Injectable({
	providedIn: 'root',
})
export class DynamicScriptLoaderServiceService {
	// observers = new Subject<[]>() ;
	public scripts: any = {}
	constructor(@Inject(PLATFORM_ID) private platformId: object) {
		if (environment.production) {
			ScriptStore.forEach((script: any) => {
				this.scripts[script.name] = {
					loaded: false,
					src: script.src,
					observer: new BehaviorSubject(null),
				}
			})
		} else {
			ScriptStoreDev.forEach((script: any) => {
				this.scripts[script.name] = {
					loaded: false,
					src: script.src,
					observer: new BehaviorSubject(null),
				}
			})
		}
	}

	load(...scripts: string[]) {
		const promises: any[] = []
		scripts.forEach((script) => promises.push(this.loadScript(script)))
		return Promise.all(promises)
	}

	loadScript(name: string) {
		if (name === 'GoogleMapAPI') return this.loadGoogleMap()
		if (isPlatformBrowser(this.platformId)) {
			return new Promise((resolve, reject) => {
				if (!this.scripts[name].loaded) {
					const script = document.createElement('script')
					script.type = 'text/javascript'
					script.src = this.scripts[name].src
					if (script.id) {
						script.id = this.scripts[name].id
					}
					if (script.readyState) {
						script.onreadystatechange = () => {
							if (
								script.readyState === 'loaded' ||
								script.readyState === 'complete'
							) {
								this.scripts[name].observer.next(true)
								script.onreadystatechange = null
								this.scripts[name].loaded = true
								resolve({
									script: name,
									loaded: true,
									status: 'Loaded',
								})
							}
						}
					} else {
						script.onload = () => {
							this.scripts[name].observer.next(true)
							this.scripts[name].loaded = true
							resolve({
								script: name,
								loaded: true,
								status: 'Loaded',
							})
						}
					}
					script.onerror = (error: any) => {
						if (error && error.isTrusted !== true) {
							// https://trello.com/c/jKwaAVft
							reject({
								script: name,
								loaded: false,
								error: error,
								source: 'dynamicScriptLoader',
							})
						}
					}
					document.getElementsByTagName('head')[0].append(script)
				} else {
					resolve({
						script: name,
						loaded: true,
						status: 'Already running',
					})
				}
			})
		}
		return
	}

	loadStripe(name: string) {
		if (name === 'stripeV3') {
			const secondScript = document.createElement('script')
			secondScript.type = 'text/javascript'
			// console.log('secondScript created')
			if (environment.production) {
				secondScript[
					secondScript.innerText === undefined
						? 'textContent'
						: 'innerText'
				] =
					'var stripe = Stripe(' +
					environment.StripeId +
					'); var elements = stripe.elements();'
			} else {
				secondScript[
					secondScript.innerText === undefined
						? 'textContent'
						: 'innerText'
				] =
					'var stripe = Stripe(' +
					environment.StripeId +
					'); var elements = stripe.elements();'
			}

			document.getElementsByTagName('head')[0].append(secondScript)
		}
	}

	loadFacebookLike() {
		const div = document.createElement('div')
		div.setAttribute('id', 'fb-like')
		div.setAttribute('class', 'fb-like my-2')
		div.setAttribute(
			'data-href',
			'https://www.facebook.com/Plan.location.appartement.paris/'
		)
		div.setAttribute('data-layout', 'standard')
		div.setAttribute('data-action', 'like')
		div.setAttribute('data-show-faces', 'true')
		div.setAttribute('data-share', 'data-share')
		document.querySelector('.searchDiv').append(div)

		// let fbLike = document.querySelector('.fb-like')
		// fbLike.classList.remove('d-none')
	}
	async loadGoogleMap() {
		if (isPlatformBrowser(this.platformId)) {
			const loader = new Loader({
				apiKey: environment.GoogleMapsKey,
			})
			await loader.importLibrary('places').catch((error) => {
				console.error(
					'Error occured while loading maps API: ' + { error }
				)
			})
			this.scripts['GoogleMapAPI'].observer.next(true)
			this.scripts['GoogleMapAPI'].loaded = true
			return loader
		}
		return
	}
}
