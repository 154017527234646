import { Component, Input, OnChanges } from '@angular/core'
import { Router } from '@angular/router'
import { NgxSmartModalService } from 'ngx-smart-modal'

import { User } from '../../../models/user.model'
import { GroupRestService } from '../../../services/rest/group/group-rest.service'
import { UserRestService } from '../../../services/rest/user/user-rest.service'
//import { WebsocketService } from '../../../services/websocket.service'
import { Group } from 'src/app/models/group.model'

import { SwalService } from '@src/app/services/swal.service'
@Component({
	selector: 'app-propose-offre-modal',
	templateUrl: './propose-offre-modal.component.html',
	styleUrls: ['./propose-offre-modal.component.scss'],
})
export class ProposeOffreModalComponent implements OnChanges {
	@Input() lang: string = '/'
	public groups: Group[] = []
	public isVide = true
	public user: User
	@Input() profileData
	@Input() currentUser: User

	constructor(
		private router: Router,
		private userRest: UserRestService,
		private swalService: SwalService,
		private groupRest: GroupRestService,
		//private websocket: WebsocketService,
		public ngxSmartModalService: NgxSmartModalService
	) {}

	async ngOnChanges() {
		this.groups = []
		if (this.currentUser) {
			this.user = await this.userRest
				.getPopulatedUserById(this.currentUser.id)
				.toPromise()

			if (this.user && this.profileData) {
				this.user.group.created.forEach((group) => {
					if (
						this.groups.filter((g) => g.id === group.id).length <= 0
					) {
						if (
							!this.isMember(group) &&
							!this.isCandidate(group) &&
							group.isActive
						) {
							this.groups.push(group)
						}
					}
				})

				this.user.group.joined.forEach((group) => {
					if (
						this.groups.filter((g) => g.id === group.id).length <= 0
					) {
						if (
							!this.isMember(group) &&
							!this.isCandidate(group) &&
							group.isActive
						) {
							this.groups.push(group)
						}
					}
				})

				if (
					this.user.group.created.filter((g) => g.isActive).length >
						0 ||
					this.user.group.joined.filter((g) => g.isActive).length > 0
				) {
					this.isVide = false
				}
			}

			//console.log(this.groups)
		}
	}

	isMember(group) {
		if (
			group.members.filter(
				(candidate) => candidate.user === this.profileData.id
			).length <= 0
		) {
			return false
		} else {
			return true
		}
	}

	isCandidate(group) {
		if (
			group.candidates.filter(
				(candidate) => candidate.user === this.profileData.id
			).length <= 0
		) {
			return false
		} else {
			return true
		}
	}

	async acceptCandidate(groupId) {
		this.ngxSmartModalService.getModal('proposeOffreModal').close()
		const userId = this.profileData.id
		const senderId = this.currentUser.id
		const updatedUser: any = await this.groupRest
			.addCandidate(groupId, userId, senderId)
			.toPromise()
			.then(() => {
				this.swalService.fire({
					icon: 'error',
					title: 'Une erreur est survenue',
					text: `Merci de retenter plus tard`,
					confirmButtonText: 'Ok',
					confirmButtonColor: '#ED6745',
				})
			})
			.catch(() => {
				this.swalService.fire({
					icon: 'success',
					title: 'Annonce recommandée',
					text: `Votre annonce a bien été proposée à ${this.profileData.firstName}.`,
					confirmButtonText: 'Super !',
					confirmButtonColor: '#ED6745',
				})
			})

		// this.userRest.currentUser.next(updatedUser)
	}

	createGroup(candidateId) {
		this.router.navigate([this.lang + '/publiez-votre-annonce'], {
			queryParams: { candidateId },
		})
	}
}
