import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
	selector: 'app-room-card',
	templateUrl: './room-card.component.html',
	styleUrls: ['./room-card.component.scss'],
})
export class RoomCardComponent {
	@Input() lang: string = 'fr'
	@Input() room: any
	@Input() edit: boolean
	@Input() allowDelete = true
	@Input() roomsLeft: number

	@Output() select: EventEmitter<string> = new EventEmitter<string>()
	@Output() delete: EventEmitter<string> = new EventEmitter<string>()

	selectRoom(): void {
		if (this.room._id) {
			this.select.emit(this.room._id.toString())
		}
	}

	deleteRoom(): void {
		if (this.room._id) {
			this.delete.emit(this.room._id.toString())
			this.edit = false
		}
	}
}
