import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { TranslateModule } from '@ngx-translate/core'
import { NgxSmartModalModule, NgxSmartModalService } from 'ngx-smart-modal'
import { ApiExternModule } from 'src/app/components/api-extern/api-extern.module'

import { ModalProfileModule } from '../profile/modal-profile.module'

import { CertifModalComponent } from './certif-modal/certif-modal.component'
import { CardsModule } from 'src/app/components/cards/cards.module'
import { lazyImgModule } from '@src/app/shared/lazy-img.module'

@NgModule({
	declarations: [CertifModalComponent],
	exports: [CertifModalComponent],
	imports: [
		CommonModule,
		ModalProfileModule,
		NgxSmartModalModule,
		// ModalGeneralModule,
		ApiExternModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule,
		CardsModule,
		lazyImgModule,
	],
	providers: [NgxSmartModalService],
})
export class ModalUserModule {}
