<div
	id="cookiesAlert"
	*ngIf="show"
	class="d-flex bg-dark justify-content-between alert-dark px-5 py-3"
	translate
>
	<p translate class="alert-dark">
		cookie.terms
		<a [routerLink]="[lang, 'conditions']" class="alert-link" translate
			>cookie.more</a
		>
		<button
			class="btn btn-primary btn-m me-4 ms-4"
			(click)="closeAlert()"
			translate
		>
			cookie.accept
		</button>
	</p>
</div>
