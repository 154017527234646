import { isPlatformBrowser } from '@angular/common'
import {
	Component,
	Input,
	OnChanges /*Inject, PLATFORM
  OnChanges_ID */,
	Output,
	EventEmitter,
	Inject,
	PLATFORM_ID,
} from '@angular/core'

@Component({
	selector: 'app-group-card',
	templateUrl: './group-card.component.html',
	styleUrls: ['./group-card.component.scss'],
})
export class GroupCardComponent implements OnChanges {
	@Input() group
	@Input() action

	@Input() origin: string
	@Input() busy: boolean
	@Input() admin: boolean

	@Output() selected: EventEmitter<any> = new EventEmitter<any>()

	public defaultPic = 'assets/img/defaultGroupPicture.png'
	public asSoonAsPossible: boolean
	public shownAddress
	public defaultProfilePic = 'assets/img/defaultProfilePicture.png'
	public description
	public title
	public lang = ''
	public check
	constructor(@Inject(PLATFORM_ID) private platformId: object) {
		if (isPlatformBrowser(this.platformId)) {
			this.lang = localStorage.getItem('lang')
				? localStorage.getItem('lang') === 'fr'
					? ''
					: '/' + localStorage.getItem('lang')
				: ''
			this.check = localStorage.getItem('lang')
		}
	}

	ngOnChanges() {
		this.asSoonAsPossible =
			new Date() >= new Date(this.group.availabilityStarts)

		this.description = this.slicing(this.group.description)
		// console.log(this.group)
		this.title = this.group?.title
		this.hideAddress()
		if (!this.group.lang) return
		if (
			this.group.lang[this.check] &&
			this.group.lang[this.check].description
		)
			this.description = this.slicing(
				this.group.lang[this.check].description
			)

		if (this.group.lang[this.check] && this.group.lang[this.check].title)
			this.title = this.group.lang[this.check].title
	}

	setSaveBack(id) {
		if (window.location.href.split('/')[3] === 'search')
			sessionStorage[window.location.href] = id
	}

	slicing(desc) {
		if (desc && desc.length)
			return desc.length > 70 ? desc.slice(0, 70) + '(...)' : desc
		else return desc
	}

	hasNumber(myString) {
		return /\d/.test(myString)
	}

	hideAddress() {
		if (this.group && this.group.address) {
			let hiddenAddress = this.group.address
			hiddenAddress = hiddenAddress.split(',')
			if (hiddenAddress.length > 1) {
				this.shownAddress =
					hiddenAddress[hiddenAddress.length - 2] +
					' , ' +
					hiddenAddress[hiddenAddress.length - 1]
			} else {
				this.shownAddress = hiddenAddress[hiddenAddress.length - 1]
			}
		}
		if (this.shownAddress) {
			const tempAddress = this.shownAddress.split(' ')
			for (let index = 0; index < tempAddress.length; index++) {
				if (
					tempAddress[index] != '' &&
					// tempAddress[index] != '' &&
					!this.hasNumber(tempAddress[index])
				) {
					this.shownAddress = tempAddress[index]
					break
				}
			}
		}
	}

	deactivateGroup(val) {
		//consolelog(val);
	}

	redirectToUrl(url) {
		window.location.href = url
	}

	choosenGroup(groupId) {
		switch (this.action) {
			case 'search':
			default:
				this.selected.emit(groupId)
				break
		}
	}
}
