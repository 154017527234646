'use strict'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, firstValueFrom, of } from 'rxjs'
import { map, takeUntil } from 'rxjs/operators'

import { Group } from '../../../models/group.model'
import { User } from '../../../models/user.model'
import { Conversation } from 'src/app/models/conversation.model'
import { environment } from '@src/environments/environment'
const API_HOST = environment.APISocket

@Injectable({
	providedIn: 'root',
})
export class GroupRestService {
	public groupData: any = new BehaviorSubject<Group>(null)
	// public groupAPI: any = new BehaviorSubject<any>(false);
	// public newGroup = new BehaviorSubject<any>(false);

	public api = global['isServer'] ? `${API_HOST}/api/` : '/api/'

	public group = 'group/'
	public highlight = 'highlight/'
	public colocs = 'colocs/'
	public all = 'all/'
	public toggle = 'toggle/'
	public fileUpload = 'fileUpload/'
	public create = 'create/'
	public update = '/update/'
	public conv = 'conversation/'

	constructor(private http: HttpClient) {
		//console.log(global['isServer'])
	}

	// GROUP CONTROLLER

	getAllGroups() {
		return this.http.get(`${this.api}${this.group}${this.all}`)
	}

	loadGroupsWithLimitApi(limit): Observable<Group[]> {
		// console.log(`${this.api}${this.group}${this.highlight}${limit}`)
		return this.http
			.get(`${this.api}${this.group}${this.highlight}${limit}`)
			.pipe(
				map((groups: Group[]) =>
					groups.map((group) => new Group(group))
				)
			)
	}

	loadColocGroupsWithLimitApi(limit): Observable<Group[]> {
		return this.http
			.get(`${this.api}${this.group}${this.colocs}${limit}`)
			.pipe(
				map((groups: Group[]) =>
					groups.map((group) => new Group(group))
				)
			)
	}

	getGroupById(groupId): Observable<Group> {
		return this.http
			.get(`${this.api}${this.group}${groupId}`)
			.pipe(map((group) => new Group(group)))
	}

	getPopulatedGroupById(groupId): Observable<Group> {
		return this.http
			.get(`${this.api}${this.group}${groupId}/populated`)
			.pipe(map((group) => new Group(group)))
	}

	getGroupsByUserId(userId): Observable<Group[]> {
		return this.http
			.get(`${this.api}${this.group}${userId}/user`)
			.pipe(
				map((groups: any[]) => groups.map((group) => new Group(group)))
			)
	}

	addRoom(groupId): Observable<Group> {
		return this.http
			.get(`${this.api}${this.group}${groupId}/room/add`)
			.pipe(map((group) => new Group(group)))
	}

	deleteRoom(groupId, roomId): Observable<Group> {
		return this.http
			.delete(`${this.api}${this.group}${groupId}/${roomId}/delete`)
			.pipe(map((group) => new Group(group)))
	}

	addLandLordByEmail(groupId, data): Observable<Conversation> {
		return this.http
			.post(`${this.api}${this.group}${groupId}/addLandLord`, data)
			.pipe(map((conv) => new Conversation(conv)))
	}

	fillRoomByMail(groupId, roomId, data): Observable<Group> {
		return this.http
			.post(
				`${this.api}${this.group}${groupId}/${roomId}/fillByEmail`,
				data
			)
			.pipe(map((group) => new Group(group)))
	}

	fillRoomByFbId(groupId, roomId, data): Observable<Group> {
		return this.http
			.post(
				`${this.api}${this.group}${groupId}/${roomId}/fillByFbId`,
				data
			)
			.pipe(map((group) => new Group(group)))
	}

	fillRoomWithoutUser(groupId, roomId): Observable<Group> {
		return this.http
			.get(
				`${this.api}${this.group}${groupId}/${roomId}/fillRoomWithoutUser`
			)
			.pipe(map((group) => new Group(group)))
	}

	editRoom(groupId, roomId, price, surface): Observable<Group> {
		return this.http
			.post(`${this.api}${this.group}${groupId}/${roomId}/edit`, {
				price: price,
				surface: surface,
			})
			.pipe(map((group) => new Group(group)))
	}

	removeCandidate(groupId, senderId): Observable<Group> {
		return this.http
			.post(`${this.api}${this.group}${groupId}/removeCandidates`, {
				senderId: senderId,
			})
			.pipe(map((group) => new Group(group)))
	}

	sendApplicationAndMessage(
		groupId,
		userId,
		message,
		startDate,
		endDate,
		isMessage
	): Observable<{ updatedUser: User; updatedGroup: Group }> {
		return this.http
			.post(
				`${this.api}${this.group}${groupId}/${userId}/sendApplicationAndMessage`,
				{ message, startDate, endDate, isMessage }
			)
			.pipe(
				map((data: any) => ({
					updatedUser: new User(data.updatedUser),
					updatedGroup: new Group(data.updatedGroup, false),
				}))
			)
	}

	sendCandidate(
		groupId,
		userId
	): Observable<{ updatedUser: User; updatedGroup: Group }> {
		return this.http
			.post(
				`${this.api}${this.group}${groupId}/${userId}/sendCandidate`,
				{}
			)
			.pipe(
				map((data: any) => ({
					updatedUser: new User(data.updatedUser),
					updatedGroup: new Group(data.updatedGroup),
				}))
			)
	}

	addCandidate(groupId, userId, senderId): Observable<User> {
		return this.http
			.post(`${this.api}${this.group}${groupId}/${userId}/addCandidate`, {
				senderId: senderId,
			})
			.pipe(map((user) => new User(user)))
	}

	removeGroupPhoto(groupId, namePhoto): Observable<Group> {
		return this.http
			.post(`${this.api}${this.group}${groupId}/removePhoto`, {
				namePhoto,
			})
			.pipe(map((group) => new Group(group)))
	}

	updateGroupPhoto(groupId, namePhoto): Observable<Group> {
		return this.http
			.post(`${this.api}${this.group}${groupId}/updatePhoto`, {
				namePhoto,
			})
			.pipe(map((group) => new Group(group)))
	}

	rotateGroupPhoto(groupId, namePhoto, angle): Observable<Group> {
		return this.http
			.post(`${this.api}${this.group}${groupId}/rotatePhoto`, {
				namePhoto,
				angle,
			})
			.pipe(map((group) => new Group(group)))
	}

	revokeApplication(
		groupId,
		userId
	): Observable<{ updatedUser: User; updatedGroup: Group }> {
		return this.http
			.get(
				`${this.api}${this.group}${groupId}/${userId}/revokeApplication`
			)
			.pipe(
				map((data: any) => ({
					updatedUser: new User(data.updatedUser),
					updatedGroup: new Group(data.updatedGroup),
				}))
			)
	}

	nextStepCandidate(groupId, userId, senderId): Observable<Group> {
		return this.http
			.post(
				`${this.api}${this.conv}${groupId}/${userId}/nextStepCandidate`,
				{
					senderId,
				}
			)
			.pipe(map((group) => new Group(group)))
	}

	acceptCandidate(groupId, userId, senderId): Observable<Group> {
		return this.http
			.post(
				`${this.api}${this.group}${groupId}/${userId}/acceptCandidate`,
				{
					senderId,
				}
			)
			.pipe(map((group) => new Group(group)))
	}

	removeCandidateScript(groupId, senderId = null) {
		return firstValueFrom(
			this.http.post(
				`${this.api}${this.group}${groupId}/removeCandidatesScript`,
				{
					senderId,
				}
			)
		)
	}

	refuseCandidate(convId, senderId): Observable<Group> {
		return this.http
			.post(`${this.api}${this.group}refuseCandidate`, {
				convId: convId,
				senderId: senderId,
			})
			.pipe(map((group) => new Group(group)))
	}

	removeGroup(groupId) {
		return this.http.delete(`${this.api}${this.group}${groupId}`)
	}

	askFolder(sourceUserId, convId) {
		return this.http
			.post(`${this.api}${this.group}askFolder`, {
				sourceUserId: sourceUserId,
				convId: convId,
			})
			.pipe(map((conversation) => new Conversation(conversation)))
	}

	shareFolder(convId) {
		return this.http
			.post(`${this.api}${this.group}shareFolder`, { convId: convId })
			.pipe(map((conv) => new Conversation(conv)))
	}

	incCptViews(groupId): Observable<Group> {
		return this.http
			.get(`${this.api}${this.group}${groupId}/incCptViews`)
			.pipe(map((group) => new Group(group)))
	}

	incCptPropose(groupId): Observable<Group> {
		return this.http
			.get(`${this.api}${this.group}${groupId}/incCptPropose`)
			.pipe(map((group) => new Group(group)))
	}

	findWith(groupId, findWith): Observable<Group> {
		return this.http
			.post(`${this.api}${this.group}${groupId}/findWith`, {
				found: findWith,
			})
			.pipe(map((group) => new Group(group)))
	}

	reactivate(groupId): Observable<Group> {
		return this.http
			.get(`${this.api}${this.group}${groupId}/reactivate`)
			.pipe(map((group) => new Group(group)))
	}

	deactivate(groupId): Observable<Group> {
		return this.http
			.get(`${this.api}${this.group}${groupId}/deactivate`)
			.pipe(map((group) => new Group(group)))
	}

	updateGroupInfomation(groupId, info): Observable<Group> {
		return this.http
			.post(`${this.api}${this.group}${groupId}${this.update}info`, {
				info,
			})
			.pipe(map((group) => new Group(group)))
	}

	updateGroupTitle(groupId, title, lang): Observable<Group> {
		return this.http
			.post(`${this.api}${this.group}${groupId}${this.update}title`, {
				title,
				lang,
			})
			.pipe(map((group) => new Group(group)))
	}

	translateGroupTitle(groupId, title, lang): Observable<Group> {
		return this.http
			.post(
				`${this.api}${this.group}${groupId}${this.update}translateTitle`,
				{
					title,
					lang,
				}
			)
			.pipe(map((group) => new Group(group)))
	}

	updateGroupDescription(groupId, description, lang): Observable<Group> {
		return this.http
			.post(
				`${this.api}${this.group}${groupId}${this.update}description`,
				{
					description,
					lang,
				}
			)
			.pipe(map((group) => new Group(group)))
	}

	updateGroupArea(groupId, area): Observable<Group> {
		return this.http
			.post(`${this.api}${this.group}${groupId}${this.update}area`, {
				area,
			})
			.pipe(map((group) => new Group(group)))
	}

	translateGroupDescription(groupId, description, lang): Observable<Group> {
		return this.http
			.post(
				`${this.api}${this.group}${groupId}${this.update}translateDescription`,
				{
					description,
					lang,
				}
			)
			.pipe(map((group) => new Group(group)))
	}

	updateGroupBudget(groupId, budget): Observable<Group> {
		return this.http
			.post(`${this.api}${this.group}${groupId}${this.update}budget`, {
				budget,
			})
			.pipe(map((group) => new Group(group)))
	}

	updateGroupAddress(
		groupId,
		address,
		lng,
		lat,
		vicinity
	): Observable<Group> {
		return this.http
			.post(`${this.api}${this.group}${groupId}${this.update}address`, {
				address,
				lng,
				lat,
				vicinity,
			})
			.pipe(map((group) => new Group(group)))
	}

	updateGroupDateStart(groupId, date): Observable<Group> {
		return this.http
			.post(
				`${this.api}${this.group}${groupId}${this.update}date/start`,
				{
					date,
				}
			)
			.pipe(map((group) => new Group(group)))
	}

	updateGroupDateEnd(groupId, date): Observable<Group> {
		return this.http
			.post(`${this.api}${this.group}${groupId}${this.update}date/end`, {
				date,
			})
			.pipe(map((group) => new Group(group)))
	}

	updateGroupUserRole(groupId, userId, role): Observable<any> {
		return this.http
			.post(`${this.api}${this.group}${groupId}${this.update}role`, {
				userId,
				role,
			})
			.pipe(map((response) => response))
	}

	getLatestGroupsPremium(limit): Observable<Group[]> {
		return this.http
			.get(`${this.api}${this.group}latestGroupsPremium/${limit}`)
			.pipe(
				map((groups: any[]) => groups.map((group) => new Group(group)))
			)
	}

	getPotentialRoommate(groupId) {
		return this.http.get(
			`${this.api}${this.group}${groupId}/getPotentialRoommate`
		)
	}

	refreshGroupCandidates(groupId) {
		return this.http.post(
			`${this.api}${this.group}${groupId}/refreshCandidates`,
			groupId
		)
	}

	lockGroup(groupId, userId, value) {
		return firstValueFrom(
			this.http.post(`${this.api}${this.group}${groupId}/lock`, {
				userId,
				value,
			})
		)
	}
}
