<button
	*ngIf="!noEmail"
	class="d-flex align-items-center justify-content-center btn d-block w-100 facebookButton"
	[disabled]="load"
	(click)="signIn()"
	translate
>
	<i class="bi bi-facebook facebook-icon"></i>
	<p translate class="m-0 ms-1 me-1">log.login.facebook</p>
</button>

<div *ngIf="noEmail">
	<p>log.noEmail</p>

	<form [formGroup]="form" (ngSubmit)="submit()">
		<!--Email-->
		<div class="mb-3 input-group">
			<div class="input-group-prepend">
				<div class="input-group-text">
					<i class="bi bi-envelope"></i>
				</div>
			</div>
			<input
				type="email"
				formControlName="email"
				placeholder="{{ 'log.placeholder.email' | translate }}"
				class="form-control"
				[ngClass]="{
					'is-invalid':
						f.email.invalid && (f.email.dirty || f.email.touched)
				}"
			/>
			<div *ngIf="f.email.errors" class="invalid-feedback">
				<div *ngIf="f.email.errors.required" translate>
					form.email.required
				</div>
				<div *ngIf="f.email.errors.email" translate>
					form.email.invalid
				</div>
			</div>
		</div>

		<!--Buttons-->
		<input
			type="submit"
			class="btn btn-lg d-block w-100 btn-primary"
			value="submit"
		/>
	</form>
</div>
