import { HttpClient } from '@angular/common/http'
import { Inject, Injectable, PLATFORM_ID } from '@angular/core'
import { JwtHelperService } from '@auth0/angular-jwt'
import { map } from 'rxjs/operators'
import { TokenService } from './../token/token.service'
import { environment } from '@src/environments/environment'
import { isPlatformBrowser } from '@angular/common'
const API_HOST = environment.APISocket

@Injectable({
	providedIn: 'root',
})
export class AuthRestService {
	public api = global['isServer'] ? `${API_HOST}/api/` : '/api/'

	public auth = 'auth/'

	private isBrowser: boolean = false

	constructor(
		private http: HttpClient,
		private jwtHelperService: JwtHelperService,
		private tokenService: TokenService,
		@Inject(PLATFORM_ID) private platformId: object
	) {
		this.isBrowser = isPlatformBrowser(this.platformId)
	}

	isLoggedIn() {
		return (
			Boolean(this.tokenService.getUserByToken()) &&
			!this.tokenService.isExpiredToken()
		)
	}

	signup(signupData) {
		return this.http
			.post(`${this.api}${this.auth}signup`, signupData)
			.pipe(map((res) => res))
	}

	signinfb(signinData) {
		return this.http
			.post(`${this.api}${this.auth}signinfb`, signinData)
			.pipe(map((res) => res))
	}

	signin(signinData) {
		return this.http
			.post(`${this.api}${this.auth}signin`, signinData)
			.pipe(map((res) => res))
	}

	setAuthTokens(token, expiresIn) {
		const user = this.getUser(token)
		let now = Math.round(new Date().getTime() / 1000)
		now += parseInt(expiresIn, 10)

		if (this.isBrowser) {
			localStorage.setItem('expires_in', JSON.stringify(now))
			localStorage.setItem('loggedInUser', user.email)
			localStorage.setItem('id_token', token)
		}
	}

	getUser(token) {
		return this.jwtHelperService.decodeToken(token)
	}

	checkMail(body) {
		return this.http
			.put(`${this.api}${this.auth}checkMail`, body)
			.pipe(map((res) => res))
	}

	autoLoginV2(body) {
		return this.http
			.put(`${this.api}${this.auth}autologinV2`, body)
			.pipe(map((res) => res))
	}

	autoLogin(body) {
		return this.http
			.put(`${this.api}${this.auth}autologin`, body)
			.pipe(map((res) => res))
	}

	autologinTemporaire(body) {
		return this.http
			.put(`${this.api}${this.auth}autologinTemporaire`, body)
			.pipe(map((res) => res))
	}

	getExpiration() {
		if (this.isBrowser) {
			const expiration = localStorage.getItem('expires_in')
			const expiresIn = JSON.parse(expiration)
			return new Date(expiresIn * 1000)
		}
	}
}
