<ngx-smart-modal
  #proposeOffreModal
  [identifier]="'proposeOffreModal'"
  [closable]="false"
  customClass="nsm-dialog-animation-btt"
>
  <ng-container *ngIf="groups.length > 0">
    <div class="modal-header">
      <h5 class="modal-title">
        {{
          'modals.profile.propose.title' 
          | translate: { firstName: profileData.firstName }
        }}
      </h5>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="proposeOffreModal.close()"
      >
      </button>
    </div>

    <div class="modal-body">
      <div class="row">
        <div class="col-sm-6 col-xs-12 mb-4" *ngFor="let group of groups">
          <app-candidate-card
            [groupData]="group"
            (save)="acceptCandidate($event)"
          >
          </app-candidate-card>
        </div>
      </div>
    </div>

    <div class="modal-footer" style="clear: both;">
      <button
        type="button"
        class="btn btn-lg btn-danger"
        (click)="proposeOffreModal.close()"
        translate
      >
        modals.form.close
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="groups.length <= 0">
    <div class="modal-header">
      <h5 class="modal-title" translate>modals.profile.propose.confirmation</h5>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="proposeOffreModal.close()"
      >
      </button>
    </div>

    <div class="modal-body" *ngIf="isVide" style="text-align: center;">
      <h6 translate>modals.profile.propose.noGroup</h6>
      <button
        type="button"
        class="btn btn-lg btn-success"
        (click)="createGroup(profileData.id)"
      >
        {{
          'modals.profile.propose.formGroup' 
          | translate: { firstName: profileData.firstName }
        }}
      </button>
    </div>
    <div class="modal-body" *ngIf="!isVide" style="text-align: center;">
      <h6 class="text-center" translate>modals.profile.propose.already</h6>
    </div>

    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-lg btn-danger"
        (click)="proposeOffreModal.close()"
        translate
      >
        modals.form.close
      </button>
    </div>
  </ng-container>
</ngx-smart-modal>
