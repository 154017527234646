export const environment = {
	production: false,
	facebookId: '211564479012085',
	APIEndpoint: 'https://jeanmichel.appartoo.com',
	APIKey: 'AIzaSyCcUOitF8A6BjTz9BWRREcgGRypTRmo6jo',
	GoogleMapsKey: 'AIzaSyCcUOitF8A6BjTz9BWRREcgGRypTRmo6jo',
	StripeId: 'pk_test_lcNtvNYtRkmCiyFo6WyrSgI5',
	CalendlyKey: 'eyJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNjI1MTQwMTQ2LCJqdGkiOiIxYzQ3MTgwMS02MDBiLTQ1MTUtYjVhNC0yZGM3MWU2OTg2NzciLCJ1c2VyX3V1aWQiOiJEREVBVzdKWlk1QktJV0xQIn0.nuKU>',
	CI: true,
	APISocket: 'https://jeanmichel.appartoo.com',
	SWITCH_SSR: true,
	LOCAL: false,
	googleAnalyticsId: 'undefined'
}
