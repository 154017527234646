import { CommonModule, NgOptimizedImage } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { TranslateModule } from '@ngx-translate/core'
import { ImgFallbackModule } from 'ngx-img-fallback'
import { CandidateCardComponent } from './candidate-card/candidate-card.component'
import { GroupCardComponent } from './group-card/group-card.component'
import { ProfileCardComponent } from './profile-card/profile-card.component'
import { RoomCardComponent } from './room-card/room-card.component'
import { SharedPipeModule } from '@src/app/shared/shared.pipe.module'
import { LazyLoadImageModule } from 'ng-lazyload-image'
import { GetPhotosModule } from '@src/app/shared/get-photos/get-photos.module'
import { lazyImgModule } from '@src/app/shared/lazy-img.module'
import { RoundImageComponent } from '../round-image/round-image.component'
@NgModule({
	declarations: [
		CandidateCardComponent,
		GroupCardComponent,
		ProfileCardComponent,
		RoomCardComponent,
	],
	exports: [
		CandidateCardComponent,
		GroupCardComponent,
		ProfileCardComponent,
		RoomCardComponent,
	],
	imports: [
		ImgFallbackModule,
		SharedPipeModule,
		LazyLoadImageModule,
		CommonModule,
		RouterModule,
		TranslateModule,
		GetPhotosModule,
		lazyImgModule,
		RoundImageComponent,
		NgOptimizedImage,
	],
})
export class CardsModule {}
