<input
	*ngIf="(loadGoogleMapApiScript$ | async) && defaultValue"
	ngx-gp-autocomplete
	#placesRef="ngx-places"
	#search
	class="form-control {{ addClass }}"
	[ngClass]="{ 'is-invalid': isInvalid }"
	[defaultValue]="defaultValue"
	[options]="optionObject"
	(onAddressChange)="handleAddressChange($event)"
	(keyup.enter)="search.blur(); (null)"
/>

<input
	*ngIf="(loadGoogleMapApiScript$ | async) && !defaultValue"
	[(ngModel)]="input"
	ngx-gp-autocomplete
	#placesRef="ngx-places"
	#search
	class="form-control {{ addClass }}"
	[ngClass]="{ 'is-invalid': isInvalid }"
	[value]="defaultValue"
	[options]="optionObject"
	(onAddressChange)="handleAddressChange($event)"
	placeholder="{{
		(placeholderValue ? placeholderValue : 'home.google') | translate
	}}"
	(keyup.enter)="search.blur(); (null)"
/>
