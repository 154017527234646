import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	Output,
	ViewChild,
	ChangeDetectionStrategy,
} from '@angular/core'
import { BaseComponent } from '@src/app/shared/components/base/base.component'
import { NgxGpAutocompleteDirective } from '@angular-magic/ngx-gp-autocomplete'
import { DynamicScriptLoaderServiceService } from '@src/app/services/dynamic-script-loader-service.service'
import { Observable } from 'rxjs'

@Component({
	selector: 'app-google-autocomplete',
	templateUrl: './google-autocomplete.component.html',
	styleUrls: ['./google-autocomplete.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GoogleAutocompleteComponent extends BaseComponent {
	@ViewChild('placesRef') placesRef: NgxGpAutocompleteDirective
	@ViewChild('search') searchElementRef: ElementRef

	@Input() placeholderValue: string
	@Input() defaultValue: string
	@Input() isInvalid: boolean

	@Input() optionObject: {
		types: string[]
		fields: string[]
	}

	@Input() addClass = ''

	@Output() save: EventEmitter<any> = new EventEmitter<any>()
	@Output() empty: EventEmitter<any> = new EventEmitter<any>()
	@Output() inputSearch: EventEmitter<string> = new EventEmitter<string>()

	@Input()
	public input = ''
	loadGoogleMapApiScript$: Observable<boolean>

	constructor(private dynScriptLoader: DynamicScriptLoaderServiceService) {
		super()
	}

	async ngOnInit() {
		if (this.optionObject.fields.indexOf('address_component') === -1) {
			this.optionObject.fields = [
				...this.optionObject.fields,
				'address_component',
			]
		}
		this.loadGoogleMapApiScript$ =
			this.dynScriptLoader.scripts['GoogleMapAPI'].observer
	}

	handleAddressChange(address) {
		if (address.address_components) {
			address.address_components.filter((i) => {
				if (i.types.indexOf('postal_code') !== -1)
					address.postal_code = i.long_name
			})
		}
		this.save.emit(address)
	}
}
