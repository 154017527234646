import { Injectable } from '@angular/core'

import { UserRestService } from './rest/user/user-rest.service'
import { TokenService } from './rest/token/token.service'
import { JwtHelperService } from '@auth0/angular-jwt'
import { Subscription, firstValueFrom } from 'rxjs'

@Injectable({
	providedIn: 'root',
})
export class AutoLoginService {
	//public currentUser = localStorage.getItem('loggedInUser')

	constructor(
		private userRest: UserRestService,
		private tokenService: TokenService,
		private jwtHelperService: JwtHelperService
	) {}

	async autoLogin(token: string | false = false) {
		const $user = this.userRest.currentUser.value

		try {
			const tkn = token ? token : this.tokenService.getToken()
			if (!tkn) {
				return $user ? this.userRest.currentUser.next(null) : 'noUser'
			}

			const userId = this.jwtHelperService.decodeToken(tkn).id

			const user = await firstValueFrom(this.userRest.getUserById(userId))

			if (!user.isBan) {
				await firstValueFrom(this.userRest.setIsOnline(user.id)).catch(
					(error) => {
						if (error.status !== 401) {
							console.error(error)
						}
					}
				)
				this.userRest.currentUser.next(user)
			}
		} catch (err) {
			console.log('IN AUTOLOGIN ===> ', err)
			//localStorage.removeItem('id_token')
			if ($user) this.userRest.currentUser.next(null)
		}
	}
}
