<ngx-smart-modal #reactivateProfileModal [identifier]="'reactivateProfileModal'" [closable]=false
  customClass='nsm-dialog-animation-btt'>

  <div class="modal-header">
    <h5 class="modal-title" translate>modals.profile.reactivate.confirmation</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="reactivateProfileModal.close()"></button>
  </div>

  <div class="modal-body">
    <h6 class="text-center" translate>modals.profile.reactivate.already</h6>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-lg btn-primary" (click)="reactivateProfileModal.close();reactiver()" translate>modals.form.yes</button>
    <button type="button" class="btn btn-lg btn-primary" (click)="reactivateProfileModal.close()" translate>modals.form.no</button>
  </div>
</ngx-smart-modal>