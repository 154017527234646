import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'localeTimezonePipe'
})
export class LocaleTimezonePipe extends DatePipe implements PipeTransform {

  transform(value: any, ...args: string[]): any {
    return super.transform(value,args[0], Intl.DateTimeFormat().resolvedOptions().timeZone, Intl.NumberFormat().resolvedOptions().locale);
  }

}
