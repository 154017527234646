// Create a capitalized pipe
import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
	name: 'titlecase',
})
export class TitleCasePipe implements PipeTransform {
	transform(value: any): any {
		try {
			return value.charAt(0).toUpperCase() + value.slice(1)
		} catch (err) {
			return value
		}
	}
}
