import { isPlatformBrowser } from '@angular/common'
import { Inject, Injectable, PLATFORM_ID } from '@angular/core'
import Swal, { SweetAlertResult } from 'sweetalert2'
@Injectable({
	providedIn: 'root',
})
export class SwalService {
	constructor(@Inject(PLATFORM_ID) private platformId: object) {}
	fire(options) {
		if (isPlatformBrowser(this.platformId)) {
			return Swal.fire({ ...options })
		}
		return false as unknown as Promise<SweetAlertResult<unknown>>
	}
	close() {
		return Swal.close()
	}
}
