import { Conversation } from './conversation.model'
import { Group } from './group.model'
import { User } from './user.model'

export class Visit {
	public id: string
	public _id: string

	public date: Date

	public status: string
	public isActive: boolean

	public conversationId: Conversation
	public groupId: Group

	public members: any[]

	public _json: any

	public toJSON() {
		return this._json
	}

	public constructor(visit, deep = true) {
		const {
			_id = null,

			date = null,

			status = null,
			isActive = null,

			groupId = null,
			conversationId = null,

			members = null,
		} = visit

		this.id = _id
		this._id = _id

		this.date = date

		this.status = status
		this.isActive = isActive

		this.groupId = deep ? new Group(groupId, false) : groupId
		this.conversationId = deep
			? new Conversation(conversationId, false)
			: conversationId

		this.members = deep
			? members.length
				? members &&
				  members.map((member) => {
						member.userId = new User(member, false)
				  })
				: []
			: members && [...members]

		this._json = visit
	}
}
