import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core'
import { UserRestService } from 'src/app/services/rest/user/user-rest.service'

@Component({
	selector: 'app-candidate-card',
	templateUrl: './candidate-card.component.html',
	styleUrls: ['./candidate-card.component.scss'],
})
export class CandidateCardComponent implements OnInit {
	@Input() profileData
	@Input() groupData
	@Output() save: EventEmitter<any> = new EventEmitter<any>()

	constructor(private userRest: UserRestService) {}

	async ngOnInit() {}

	submit(userId) {
		this.save.emit(userId)
	}
}
