import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, EMPTY, firstValueFrom } from 'rxjs'
import { map } from 'rxjs/operators'
import { environment } from '@src/environments/environment'

import { Group } from '../../../models/group.model'
import { User } from '../../../models/user.model'
import { Visit } from '@src/app/models/visit.model'
import { TokenService } from '../token/token.service'
const API_HOST = environment.APISocket

@Injectable({
	providedIn: 'root',
})
export class UserRestService {
	public currentUser = new BehaviorSubject<User>(null)
	public profileData = new BehaviorSubject<User>(null)
	// public userAPI: any = new BehaviorSubject<any>(false);

	public gmap = 'https://maps.googleapis.com/maps/api/distancematrix/'
	public gmapKey = environment.GoogleMapsKey

	public api = global['isServer'] ? `${API_HOST}/api/` : '/api/'

	public user = 'user/'

	public update = '/update/'
	public visit = '/visit/'
	public situation = '/situation/'
	public folder = '/folder/'

	constructor(private http: HttpClient, private tokenService: TokenService) {}

	// USER CONTROLLER
	getCurrentUserValue(): User {
		return this.currentUser.getValue()
	}
	async getRefreshUserValue() {
		try {
			if (this.currentUser.getValue()?.id) {
				const updatedUser = await firstValueFrom(
					this.getUserById(this.currentUser.getValue().id)
				)
				this.currentUser.next(updatedUser)
				return this.getCurrentUserValue()
			}
			return {} as User
		} catch (error) {
			console.error(error)
			throw error
		}
	}

	async refreshUserValue() {
		try {
			const uid = this.tokenService.getUserId()

			if (uid) {
				const updatedUser = await firstValueFrom(this.getUserById(uid))
				this.currentUser.next(updatedUser)
			} else {
				this.currentUser.next(null)
			}
		} catch (err) {
			console.error(err)
			throw err
		}
	}

	getAllUsers() {
		return this.http.get(`${this.api}${this.user}all`)
	}

	getLastHighlightUsers(limit): Observable<User[]> {
		return this.http
			.get(`${this.api}${this.user}highlight/${limit}`)
			.pipe(
				map((users: any[]) =>
					users.map((user) => new User(user, false))
				)
			)
	}

	getLastHighlightAgencies(limit): Observable<User[]> {
		return this.http
			.get(`${this.api}${this.user}highlightAgency/${limit}`)
			.pipe(
				map((users: any[]) =>
					users.map((user) => new User(user, false))
				)
			)
	}

	getLatestUsersPremium(limit): Observable<User[]> {
		return this.http
			.get(`${this.api}${this.user}latestUsersPremium/${limit}`)
			.pipe(map((users: any[]) => users.map((user) => new User(user))))
	}

	getUserById(userId): Observable<User> {
		if (!userId) return EMPTY
		return this.http
			.get(`${this.api}${this.user}${userId}`)
			.pipe(map((user) => new User(user)))
	}

	async getUserByPhone(phoneNumber, userId) {
		return await firstValueFrom(
			this.http.get(
				`${this.api}${this.user}${userId}/${phoneNumber}/getUser`
			)
		)
	}

	removeUser(userId): Observable<User> {
		return this.http
			.delete(`${this.api}${this.user}${userId}`)
			.pipe(map((user) => new User(user)))
	}

	getPopulatedUserById(userId): Observable<User> {
		return this.http
			.get(`${this.api}${this.user}${userId}/populated`)
			.pipe(map((user) => new User(user)))
	}

	getUserDocument(url): Observable<any> {
		return this.http
			.get(url, { responseType: 'blob' })
			.pipe(map((blob) => URL.createObjectURL(blob)))
	}
	async getUserDocument2(url) /*: Observable<any>*/ {
		try {
			const test = await firstValueFrom(
				this.http.get(environment.APISocket + '/api' + url, {
					responseType: 'blob',
				})
			)
			return URL.createObjectURL(test)
		} catch (error) {
			console.error('REST', url, error.message)
			throw error
		}
	}
	downloadUserDocument(url, fileName): Observable<any> {
		return this.http.get(url, { responseType: 'blob' }).pipe(
			map((blob) => {
				const fileURL = URL.createObjectURL(blob)
				const fileLink = document.createElement('a')
				fileLink.href = fileURL

				// it forces the name of the downloaded file
				fileLink.download = fileName

				// triggers the click event
				fileLink.click()
			})
		)
	}

	showUserDocument(url, fileName): Observable<any> {
		return this.http.get(url, { responseType: 'blob' }).pipe(
			map((blob) => {
				const fileURL = URL.createObjectURL(blob)
				const fileLink = document.createElement('a')
				fileLink.href = fileURL
				fileLink.target = '_blank'

				// triggers the click event
				fileLink.click()
			})
		)
	}

	getUserByEmail(userEmail): Observable<User> {
		return this.http.get(`${this.api}${this.user}${userEmail}/mail`).pipe(
			map((user) => {
				if (!user) return null
				return new User(user)
			})
		)
	}

	getProfileWizardSteps(userId): Observable<any> {
		return this.http.get(`${this.api}${this.user}${userId}/steps`)
	}

	setIsOnline(userId): Observable<any> {
		return this.http
			.get(`${this.api}${this.user}${userId}/setIsOnline`)
			.pipe(map((user) => new User(user)))
	}

	setIsOffline(userId) {
		return this.http
			.get(`${this.api}${this.user}${userId}/setIsOffline`)
			.pipe(map((user) => new User(user)))
	}

	blockMails(userId): Observable<User> {
		return this.http
			.get(`${this.api}${this.user}${userId}/blockMails`)
			.pipe(map((user) => new User(user)))
	}

	createNewGroup(userId): Observable<Group> {
		return this.http
			.get(`${this.api}${this.user}${userId}/createGroup`)
			.pipe(map((group) => new Group(group)))
	}

	translateNewGroup(groupId): Observable<Group> {
		return this.http
			.get(`${this.api}${this.user}${groupId}/translateGroup`)
			.pipe(map((group) => new Group(group)))
	}

	addFavorite(userId, groupId): Observable<User> {
		return this.http
			.post(`${this.api}${this.user}${userId}/addFavoriteGroup`, {
				groupId,
			})
			.pipe(map((user) => new User(user)))
	}

	removeFavorite(userId, groupId): Observable<User> {
		return this.http
			.post(`${this.api}${this.user}${userId}/removeFavoriteGroup`, {
				groupId,
			})
			.pipe(map((user) => new User(user)))
	}

	setSocketId(userId, socketId): Observable<User> {
		return this.http
			.post(`${this.api}${this.user}${userId}/setSocketId`, {
				socketId: socketId,
			})
			.pipe(map((user) => new User(user)))
	}

	banishUser(userId): Observable<User> {
		return this.http
			.get(`${this.api}${this.user}${userId}/banish`)
			.pipe(map((user) => new User(user)))
	}

	passwordReset(userEmail, password): Observable<User> {
		return this.http
			.post(`${this.api}${this.user}${userEmail}/resetPassword`, {
				password,
			})
			.pipe(map((user) => new User(user)))
	}

	deactivateProfile(userId): Observable<User> {
		return this.http
			.get(`${this.api}${this.user}${userId}/deactivate`)
			.pipe(map((user) => new User(user)))
	}

	requestCertif(userId, emailTo): Observable<any> {
		return this.http.post(`${this.api}${this.user}${userId}/certificat`, {
			emailTo,
		})
	}

	reactivateUser(userId): Observable<User> {
		return this.http
			.get(`${this.api}${this.user}${userId}/reactivate`)
			.pipe(map((user) => new User(user)))
	}

	revokeAllApplications(userId): Observable<User> {
		return this.http
			.get(`${this.api}${this.user}${userId}/revokeAllApplications`)
			.pipe(map((user) => new User(user)))
	}

	createUser(data): Observable<User> {
		return this.http
			.post(`${this.api}${this.user}create`, data)
			.pipe(map((user) => new User(user)))
	}

	incCptViews(userId): Observable<User> {
		return this.http
			.get(`${this.api}${this.user}${userId}/incCptViews`)
			.pipe(map((user) => new User(user)))
	}

	hasBuyCertif(userId): Observable<any> {
		return this.http.get(`${this.api}${this.user}${userId}/hasBuyCertif`)
	}

	sendGroupReport(userId, groupId, data): Observable<Group> {
		return this.http
			.put(
				`${this.api}${this.user}${userId}/${groupId}/sendGroupReport`,
				data
			)
			.pipe(map((group) => new Group(group)))
	}

	updateUserGender(userId, gender): Observable<User> {
		return this.http
			.post(`${this.api}${this.user}${userId}${this.update}gender`, {
				gender,
			})
			.pipe(map((user) => new User(user)))
	}

	updateUserIncome(userId, income): Observable<User> {
		return this.http
			.post(`${this.api}${this.user}${userId}${this.update}income`, {
				income,
			})
			.pipe(map((user) => new User(user)))
	}

	updateUserIsHosted(userId, isHosted): Observable<User> {
		return this.http
			.post(`${this.api}${this.user}${userId}${this.update}isHosted`, {
				isHosted,
			})
			.pipe(map((user) => new User(user)))
	}

	updateUserEnd8Month(userId, end8Month): Observable<User> {
		return this.http
			.post(`${this.api}${this.user}${userId}${this.update}end8Month`, {
				end8Month,
			})
			.pipe(map((user) => new User(user)))
	}

	updateUserActivity2Years(userId, activity2Years): Observable<User> {
		return this.http
			.post(
				`${this.api}${this.user}${userId}${this.update}activity2Years`,
				{
					activity2Years,
				}
			)
			.pipe(map((user) => new User(user)))
	}

	updateUserBadge(userId, badges): Observable<User> {
		return this.http
			.post(`${this.api}${this.user}${userId}${this.update}badges`, {
				badges,
			})
			.pipe(map((user) => new User(user)))
	}

	updateUserBirthdate(userId, birthdate): Observable<User> {
		return this.http
			.post(`${this.api}${this.user}${userId}${this.update}birthdate`, {
				birthdate,
			})
			.pipe(map((user) => new User(user)))
	}

	updateUserBudget(userId, budget): Observable<User> {
		return this.http
			.post(`${this.api}${this.user}${userId}${this.update}budget`, {
				budget,
			})
			.pipe(map((user) => new User(user)))
	}

	updateUserTarget(userId, target): Observable<User> {
		return this.http
			.post(`${this.api}${this.user}${userId}${this.update}target`, {
				target,
			})
			.pipe(map((user) => new User(user)))
	}

	updateUserSearchDate(userId, searchDate): Observable<User> {
		return this.http
			.post(`${this.api}${this.user}${userId}${this.update}searchDate`, {
				searchDate,
			})
			.pipe(map((user) => new User(user)))
	}

	updateUserSearchDuration(userId, duration): Observable<User> {
		return this.http
			.post(`${this.api}${this.user}${userId}${this.update}duration`, {
				duration,
			})
			.pipe(map((user) => new User(user)))
	}

	updateUserEndDate(userId, endDate): Observable<User> {
		return this.http
			.post(`${this.api}${this.user}${userId}${this.update}endDate`, {
				endDate,
			})
			.pipe(map((user) => new User(user)))
	}

	updateUserSearchFurniture(userId, hasFurniture): Observable<User> {
		return this.http
			.post(
				`${this.api}${this.user}${userId}${this.update}searchFurniture`,
				{
					hasFurniture,
				}
			)
			.pipe(map((user) => new User(user)))
	}

	updateUserNumberOfRoomsWanted(userId, roomsNb): Observable<User> {
		return this.http
			.post(`${this.api}${this.user}${userId}${this.update}roomsNb`, {
				roomsNb,
			})
			.pipe(map((user) => new User(user)))
	}

	updateUserContractDuration(userId, contractDuration): Observable<User> {
		return this.http
			.post(
				`${this.api}${this.user}${userId}${this.update}contractDuration`,
				{
					contractDuration,
				}
			)
			.pipe(map((user) => new User(user)))
	}

	updateSearchRange(userId, searchRange): Observable<User> {
		return this.http
			.post(`${this.api}${this.user}${userId}${this.update}searchRange`, {
				searchRange,
			})
			.pipe(map((user) => new User(user)))
	}

	updateUserDescription(userId, description): Observable<User> {
		return this.http
			.post(`${this.api}${this.user}${userId}${this.update}description`, {
				description,
			})
			.pipe(map((user) => new User(user)))
	}

	rotateUserPhoto(userId, angle): Observable<User> {
		return this.http
			.post(`${this.api}${this.user}${userId}${this.update}rotatePhoto`, {
				angle,
			})
			.pipe(map((user) => new User(user)))
	}

	checkout(userId, intent) {
		return this.http.post(`${this.api}${this.user}${userId}/checkout`, {
			intent,
		})
	}

	getStripeClientSecret(userId): Observable<string> {
		return this.http
			.get(`${this.api}${this.user}${userId}/getStripeClientSecret`)
			.pipe(map((secret) => String(secret)))
	}
	intentPaymentCertif(userId): Observable<string> {
		return this.http
			.get(`${this.api}${this.user}${userId}/intentPaymentCertif`)
			.pipe(map((secret) => String(secret)))
	}
	intentPaymentRent(userId, groupId): Observable<string> {
		return this.http
			.get(
				`${this.api}${this.user}${userId}/intentPaymentRent/${groupId}`
			)
			.pipe(map((secret) => String(secret)))
	}
	saveCard(userId, groupId, paymentMeth) {
		return this.http.post(`${this.api}${this.user}${userId}/saveCard`, {
			groupId,
			paymentMeth,
		})
	}

	saveIban(userId, ibanSource, tokenId) {
		// console.log('send Iban: ', `${this.api}${this.user}${userId}/saveIban`, ' token: ', tokenId);
		return this.http.post(`${this.api}${this.user}${userId}/saveIban`, {
			ibanSource,
			tokenId,
		})
	}

	updateUserEmail(userId, email): Observable<User> {
		return this.http
			.post(`${this.api}${this.user}${userId}${this.update}email`, {
				email,
			})
			.pipe(map((user) => new User(user)))
	}

	updateUserFindWith(userId, findWith): Observable<User> {
		return this.http
			.post(`${this.api}${this.user}${userId}${this.update}findWith`, {
				findWith,
			})
			.pipe(map((user) => new User(user)))
	}

	updateUserFirstName(userId, firstName): Observable<User> {
		return this.http
			.post(`${this.api}${this.user}${userId}${this.update}firstName`, {
				firstName,
			})
			.pipe(map((user) => new User(user)))
	}

	updateUserLastName(userId, lastName): Observable<User> {
		return this.http
			.post(`${this.api}${this.user}${userId}${this.update}lastName`, {
				lastName,
			})
			.pipe(map((user) => new User(user)))
	}

	updateUserPhoneNumber(userId, phoneNumber): Observable<User> {
		return this.http
			.post(`${this.api}${this.user}${userId}${this.update}phoneNumber`, {
				phoneNumber,
			})
			.pipe(map((user) => new User(user)))
	}

	updateUserSharePhoneNumber(userId, sharePhone): Observable<User> {
		return this.http
			.post(`${this.api}${this.user}${userId}${this.update}sharePhone`, {
				sharePhone,
			})
			.pipe(map((user) => new User(user)))
	}

	updateUserRelationshipStatus(userId, relationshipStatus): Observable<User> {
		return this.http
			.post(
				`${this.api}${this.user}${userId}${this.update}relationshipStatus`,
				{ relationshipStatus }
			)
			.pipe(map((user) => new User(user)))
	}

	updateUserSearch(userId, address, lng, lat, vicinity): Observable<User> {
		return this.http
			.post(`${this.api}${this.user}${userId}${this.update}search`, {
				address,
				lng,
				lat,
				vicinity,
			})
			.pipe(map((user) => new User(user)))
	}

	updateUsersupIncome(userId, supIncome): Observable<User> {
		return this.http
			.post(`${this.api}${this.user}${userId}${this.update}supIncome`, {
				supIncome,
			})
			.pipe(map((user) => new User(user)))
	}

	updateUserByFacebook(userId, id, accessToken) {
		return this.http
			.post(`${this.api}${this.user}${userId}${this.update}byFacebook`, {
				id,
				accessToken,
			})
			.pipe(map((res) => res))
	}

	addSituationStatus(
		userId,
		situationStatus,
		movingForWork = false,
		town = null,
		place = null,
		start = null,
		employer = null,
		periodDuration = null
	): Observable<User> {
		return this.http
			.post(
				`${this.api}${this.user}${userId}${this.situation}status/add`,
				{
					situationStatus,
					movingForWork,
					town,
					place,
					start,
					employer,
					periodDuration,
				}
			)
			.pipe(map((user) => new User(user)))
	}

	// addSituationWorkingStatus(userId, situationStatus, userTown): Observable<User> {
	//   return this.http
	//     .post(`${this.api}${this.user}${userId}${this.situation}status/add`, {
	//       situationStatus, movingForWork: true, town: userTown
	//     })
	//     .pipe(map((user) => new User(user)))
	// }

	addUserPlace(userId, situationStatus, placeData): Observable<User> {
		return this.http
			.post(
				`${this.api}${this.user}${userId}${this.situation}place/add`,
				{
					situationStatus,
					placeData,
				}
			)
			.pipe(map((user) => new User(user)))
	}

	deleteUserSituation(userId, situationId): Observable<User> {
		return this.http
			.get(
				`${this.api}${this.user}${userId}${this.situation}${situationId}/delete`
			)
			.pipe(map((user) => new User(user)))
	}

	deleteUserPlace(userId, situationId, placeId): Observable<User> {
		return this.http
			.get(
				`${this.api}${this.user}${userId}${this.situation}${situationId}/place/${placeId}/delete`
			)
			.pipe(map((user) => new User(user)))
	}

	revalidateFolder(userId, sourceUserId, targetUserId): Observable<User> {
		return this.http
			.post(`${this.api}${this.user}${userId}${this.folder}revalidate`, {
				sourceUserId,
				targetUserId,
			})
			.pipe(map((user) => new User(user)))
	}

	askCertif(userId): Observable<User> {
		return this.http
			.get(`${this.api}${this.user}${userId}/askCertif`)
			.pipe(map((user) => new User(user)))
	}

	askPremium(userId, premiumInfos): Observable<User> {
		return this.http
			.post(`${this.api}${this.user}${userId}/askPremium`, {
				premiumInfos,
			})
			.pipe(map((user) => new User(user)))
	}

	extendPremium(userId, premiumInfos): Observable<User> {
		return this.http
			.post(`${this.api}${this.user}${userId}/extendPremium`, {
				premiumInfos,
			})
			.pipe(map((user) => new User(user)))
	}

	stopPremium(userId): Observable<User> {
		return this.http
			.get(`${this.api}${this.user}${userId}/stopPremium`)
			.pipe(map((user) => new User(user)))
	}

	relaodPremium(userId): Observable<User> {
		return this.http
			.get(`${this.api}${this.user}${userId}/restartPremium`)
			.pipe(map((user) => new User(user)))
	}

	acceptCertif(userId): Observable<User> {
		return this.http
			.get(`${this.api}${this.user}${userId}/acceptCertif`)
			.pipe(map((user) => new User(user)))
	}

	refuseCertif(userId): Observable<User> {
		return this.http
			.get(`${this.api}${this.user}${userId}/refuseCertif`)
			.pipe(map((user) => new User(user)))
	}

	deleteDocumentFolder(userId, typeDocument): Observable<User> {
		return this.http
			.get(
				`${this.api}${this.user}${userId}${this.folder}${typeDocument}/delete`
			)
			.pipe(map((user) => new User(user)))
	}

	validDocumentFolder(userId, typeDocument): Observable<User> {
		return this.http
			.get(
				`${this.api}${this.user}${userId}${this.folder}${typeDocument}/valid`
			)
			.pipe(map((user) => new User(user)))
	}

	acceptCertDoss(userId): Observable<User> {
		return this.http
			.get(`${this.api}${this.user}${userId}/validCertDoss`)
			.pipe(map((user) => new User(user)))
	}

	cancelDocumentFolder(userId, typeDocument): Observable<User> {
		return this.http
			.get(
				`${this.api}${this.user}${userId}${this.folder}${typeDocument}/cancel`
			)
			.pipe(map((user) => new User(user)))
	}

	setAskValid(userId): Observable<User> {
		return this.http
			.get(`${this.api}${this.user}${userId}${this.folder}setAskValid`)
			.pipe(map((user) => new User(user)))
	}

	unsetAskValid(userId): Observable<User> {
		return this.http
			.get(`${this.api}${this.user}${userId}${this.folder}unsetAskValid`)
			.pipe(map((user) => new User(user)))
	}

	deleteGivenAccess(userId): Observable<User> {
		return this.http
			.get(
				`${this.api}${this.user}${userId}${this.folder}givenAccess/delete`
			)
			.pipe(map((user) => new User(user)))
	}

	toggleIsSearching(userId): Observable<User> {
		return this.http
			.get(`${this.api}${this.user}${userId}/toggle/isSearching`)
			.pipe(map((user) => new User(user)))
	}

	toggleAcceptSms(userId): Observable<User> {
		return this.http
			.get(`${this.api}${this.user}${userId}/toggle/acceptSms`)
			.pipe(map((user) => new User(user)))
	}

	toggleAcceptMail(userId): Observable<User> {
		return this.http
			.get(`${this.api}${this.user}${userId}/toggle/acceptMail`)
			.pipe(map((user) => new User(user)))
	}

	checkUserEmail(userId): Observable<User> {
		return this.http
			.get(`${this.api}${this.user}${userId}/checkUserEmail`)
			.pipe(map((user) => new User(user)))
	}

	sendEmailConfirmation(userId) {
		return this.http.post(
			`${this.api}${this.user}${userId}/sendEmailConfirmation`,
			{ userId },
			{ responseType: 'text' }
		)
	}

	addGuarantor(userId, firstName, lastName, email, income): Observable<User> {
		return this.http
			.post(`${this.api}${this.user}${userId}/guarantor/add`, {
				firstName,
				lastName,
				email,
				income,
			})
			.pipe(map((user) => new User(user)))
	}

	deleteGuarantor(userId, guarantorId): Observable<User> {
		return this.http
			.get(
				`${this.api}${this.user}${userId}/guarantor/${guarantorId}/delete`
			)
			.pipe(map((user) => new User(user)))
	}

	updateHasGuarantorVisa(userId, hasVisa): Observable<User> {
		return this.http
			.post(`${this.api}${this.user}${userId}/guarantorHasVisa`, {
				hasVisa,
			})
			.pipe(map((user) => new User(user)))
	}

	updateGuarantorVisaAmount(userId, visaleAmount): Observable<User> {
		return this.http
			.post(`${this.api}${this.user}${userId}/visaleAmount`, {
				visaleAmount,
			})
			.pipe(map((user) => new User(user)))
	}

	updateStatus(userId, role): Observable<any> {
		return this.http.post(
			`${this.api}${this.user}${userId}/update/status`,
			{
				status: role,
			}
		)
	}

	getRole(userId): Observable<any> {
		return this.http.get(`${this.api}${this.user}${userId}/role`)
	}

	isEligible(userId): Observable<any> {
		return this.http.get(`${this.api}${this.user}${userId}/isEligible`)
	}

	getAllowedBill(userId): Observable<any> {
		return this.http.get(`${this.api}${this.user}${userId}/allowedBill`)
	}

	hasSubmittedId(userId) {
		return this.http.get(`${this.api}${this.user}${userId}/hasSubmittedId`)
	}

	getMovingForWorkDistance(address, company) {
		return this.http.get(
			`${this.gmap}json?units=metric&origin=${address[0]},${address[1]}&destination=${company[0]},${company[1]}&key=${this.gmapKey}`
		)
	}

	getAppointmentInfo(calendlyURL: string): Observable<any> {
		const headers = new HttpHeaders({
			Authorization: 'Bearer ' + environment.CalendlyKey,
		})
		return this.http.get(calendlyURL, { headers: headers })
	}

	setAppointmentDate(userId: string, date: Date): Observable<any> {
		return this.http.post(
			`${this.api}${this.user}${userId}/appointmentDate`,
			{
				date,
			}
		)
	}

	sendAppointmentMessage(userId, message): Observable<User> {
		return this.http
			.post(`${this.api}${this.user}${userId}/sendAppointmentMessage`, {
				message,
			})
			.pipe(map((user) => new User(user)))
	}

	setAppointment(
		userId,
		date,
		catcherId,
		duration,
		event_link,
		invitee_link
	): Observable<User> {
		return this.http
			.post(`${this.api}${this.user}${userId}/setAppointment`, {
				date,
				catcherId,
				duration,
				event_link,
				invitee_link,
			})
			.pipe(map((user) => new User(user)))
	}

	getPhoneNumber(userId): any {
		return this.http
			.get(`${this.api}${this.user}${userId}/phoneNumber`)
			.pipe(map((result) => result))
	}

	setSeenPhoneNumber(userId, ownerId): any {
		return this.http
			.post(`${this.api}${this.user}${userId}/phoneNumber`, { ownerId })
			.pipe(map((result) => result))
	}

	setGuarantConfirmed(userId, targetId): any {
		return this.http
			.post(`${this.api}${this.user}${userId}/guarantConfirmed`, {
				targetId,
			})
			.pipe(map((result) => result))
	}

	checkSeenPhoneNumber(userId, ownerId): any {
		return this.http
			.get(
				`${this.api}${this.user}${userId}/${ownerId}/checkSeenPhoneNumber`
			)
			.pipe(map((result) => result))
	}

	changeAppointmentStep(userId, forward): Observable<User> {
		return this.http
			.post(`${this.api}${this.user}${userId}/changeAppointmentStep`, {
				forward,
			})
			.pipe(map((user) => new User(user)))
	}

	getNotifications(userId): any {
		return this.http
			.get(`${this.api}${this.user}${userId}/getNotifications`)
			.pipe(map((result) => result))
	}

	updateUserRentalFiles(
		userId,
		documentType,
		document,
		action,
		guarantorId = null
	): Observable<User> {
		const path = guarantorId
			? `/updateUserRentalFiles/${guarantorId}`
			: '/updateUserRentalFiles'
		// console.log(path);
		return this.http
			.post(`${this.api}${this.user}${userId}${path}`, {
				documentType,
				document,
				action,
			})
			.pipe(map((user) => new User(user)))
	}

	updateUserRentalGuarantors(
		userId,
		guarantorInfos,
		action
	): Observable<User> {
		return this.http
			.post(
				`${this.api}${this.user}${userId}/updateUserRentalGuarantors`,
				{
					guarantorInfos,
					action,
				}
			)
			.pipe(map((user) => new User(user)))
	}

	//#region --------------- VISITS ACTIONS ---------------

	createVisit(userId, groupId, conversationId, date, fromOwner = false) {
		return this.http.post(`${this.api}${this.user}${userId}/visit/create`, {
			groupId,
			conversationId,
			date,
			fromOwner,
		})
	}

	updateVisit(userId, visitId, changes) {
		return this.http.post(
			`${this.api}${this.user}${userId}/visit/update/${visitId}`,
			{ changes }
		)
	}

	getVisitById(visitId): Observable<Visit> {
		return this.http
			.get(`${this.api}${this.visit}${visitId}`)
			.pipe(map((visit) => new Visit(visit)))
	}

	shouldILockAdd(userId, lastName, email) {
		return this.http.post(`${this.api}${this.user}${userId}/shouldLock`, {
			email,
			lastName,
		})
	}

	getMissingFiles(userId: string): any {
		return this.http.get(`${this.api}${this.user}${userId}/getMissingFiles`)
	}
	//#endregion

	//#region --------------- 'CATCHER PREFERENCES' ---------------

	updateCatcherPreferences(userId, preferences): Observable<User> {
		return this.http
			.post(
				`${this.api}${this.user}${userId}/catcherPreferences/update`,
				{ preferences }
			)
			.pipe(map((user) => new User(user)))
	}

	//#endregion
}
