import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { TranslateModule } from '@ngx-translate/core'
import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete'
import { FacebookButtonComponent } from './facebook-button/facebook-button.component'
import { GoogleAutocompleteComponent } from './google-autocomplete/google-autocomplete.component'
import { BaseComponent } from '@src/app/shared/components/base/base.component'
import { environment } from 'src/environments/environment'
import { Loader } from '@googlemaps/js-api-loader'

@NgModule({
	declarations: [
		FacebookButtonComponent,
		GoogleAutocompleteComponent,
		BaseComponent,
	],
	exports: [FacebookButtonComponent, GoogleAutocompleteComponent],
	imports: [
		NgxGpAutocompleteModule,
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule,
	],
	providers: [
		{
			provide: Loader,
			useValue: new Loader({
				apiKey: environment.GoogleMapsKey,
			}),
		},
	],
})
export class ApiExternModule {}
