import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
	name: 'getPhotos',
	pure: true,
})
export class GetPhotosPipe implements PipeTransform {
	public transform(path: string, ...args): string {
		return path.startsWith('public/')
			? path
			: `public/${args[1]}/${args[0]}/${path}`
	}
}
