import { registerLocaleData } from '@angular/common'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import {
	PLATFORM_ID,
	NgModule,
	LOCALE_ID,
	ErrorHandler,
	APP_ID,
} from '@angular/core'
import { JwtInterceptor, JwtModule } from '@auth0/angular-jwt'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { NgxSmartModalModule, NgxSmartModalService } from 'ngx-smart-modal'
import { HttpInterceptorService } from './http-interceptor.service'
import * as SentryBrowser from '@sentry/browser' // browser SDK
import { ErrorLogger } from './services/errorlogger'
import { SharedPipeModule } from './shared/shared.pipe.module'
import localeFr from '@angular/common/locales/fr'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { CookiesAlertComponent } from './components/cookies-alert/cookies-alert.component'
import { ModalUserModule } from './modals/user/modal-user.module'

registerLocaleData(localeFr)

export function jwtTokenGetter() {
	if (localStorage) {
		return localStorage.getItem('id_token')
	}
}

export const MY_CUSTOM_FORMATS = {
	fullPickerInput: 'DD/MM/YYY HH/mm/ss',
	parseInput: 'DD/MM/YYYY HH:mm:ss',
	datePickerInput: 'DD/MM/YYYY',
	timePickerInput: 'LT',
	monthYearLabel: 'MMM YYYY',
	dateA11yLabel: 'LL',
	monthYearA11yLabel: 'MMMM YYYY',
}

@NgModule({
	declarations: [AppComponent],
	imports: [
		HttpClientModule,
		AppRoutingModule,
		SharedPipeModule,
		NgxSmartModalModule.forRoot(),
		//LazyLoadImageModule,
		BrowserAnimationsModule,
		JwtModule.forRoot({
			config: {
				tokenGetter: jwtTokenGetter,
				// whitelistedDomains: ['localhost:3000', 'localhost:4200', 'localhost:4000'],
				// headerName: 'Authorization',
				// authScheme: 'Bearer'
			},
		}),
		CookiesAlertComponent,
		ModalUserModule,
	],
	providers: [
		NgxSmartModalService,
		{ provide: APP_ID, useValue: 'appartooFrontV2' },
		{
			provide: ErrorHandler,
			useFactory: ErrorLogger.initWith(SentryBrowser),
		},
		{ provide: PLATFORM_ID, useValue: 'browser' },

		{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpInterceptorService,
			multi: true,
		},
		//{ provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks },
		{ provide: LOCALE_ID, useValue: 'fr-FR' },
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
