import {
	Component,
	EventEmitter,
	Inject,
	OnInit,
	Output,
	PLATFORM_ID,
} from '@angular/core'
import {
	UntypedFormBuilder,
	UntypedFormGroup,
	Validators,
} from '@angular/forms'
import { AuthRestService } from '@src/app/services/rest/auth/auth-rest.service'
import { BaseComponent } from '@src/app/shared/components/base/base.component'
import { NgxSmartModalService } from 'ngx-smart-modal'
import { take, takeUntil } from 'rxjs/operators'
import { SwalService } from '@src/app/services/swal.service'
import { FacebookService } from '../../../services/facebook/facebook.service'
import { UserRestService } from '../../../services/rest/user/user-rest.service'
import { Router, ActivatedRoute } from '@angular/router'
import { TokenService } from '@src/app/services/rest/token/token.service'
import { TranslateService } from '@ngx-translate/core'
import { isPlatformBrowser } from '@angular/common'
import { DynamicScriptLoaderServiceService } from '@src/app/services/dynamic-script-loader-service.service'
import { firstValueFrom } from 'rxjs'

@Component({
	selector: 'app-facebook-button',
	templateUrl: './facebook-button.component.html',
	styleUrls: ['./facebook-button.component.scss'],
})
export class FacebookButtonComponent extends BaseComponent implements OnInit {
	lang = 'fr'

	@Output() userSubmit: EventEmitter<any> = new EventEmitter<any>()

	@Output() isLoading: EventEmitter<any> = new EventEmitter<any>()

	form: UntypedFormGroup
	public noEmail: any = false
	private userId: string
	private user
	private token
	public load = false
	private expiresIn
	facebookApp = false

	constructor(
		@Inject(PLATFORM_ID) private platformId: object,
		private swalService: SwalService,
		private facebook: FacebookService,
		private userRest: UserRestService,
		private fb: UntypedFormBuilder,
		public ngxSmartModalService: NgxSmartModalService,
		private authRest: AuthRestService,
		private router: Router,
		private route: ActivatedRoute,
		private translate: TranslateService,
		private tokenService: TokenService,
		private dynScriptLoader: DynamicScriptLoaderServiceService
	) {
		super()
		if (isPlatformBrowser(platformId)) {
			this.lang =
				localStorage.getItem('lang') === 'fr'
					? '/'
					: '/' + localStorage.getItem('lang')
			facebook.stream
				.pipe(takeUntil(this.unsubscribe$))
				.subscribe((data) => this.setLoad(data))
		}
	}
	setLoad(data) {
		this.load = data
		this.isLoading.emit(this.load)
	}
	get f() {
		return this.form.controls
	}

	ngOnInit() {
		if (isPlatformBrowser(this.platformId)) {
			setTimeout(() => {
				this.dynScriptLoader
					.loadScript('FacebookConnect')
					.then((loaded) => {
						this.load = false
						this.form = this.fb.group({
							email: [
								'',
								[Validators.required, Validators.email],
							],
						})
						this.facebookApp = this.isFacebookApp()
						if (this.facebookApp) {
							const {
								expires_in = undefined,
								access_token = undefined,
							} = {
								...this.getFacebookAppInfo(),
							}
							this.signInFacebookApp(expires_in, access_token)
						}
					})
			}, 0)
		}
	}

	async signIn() {
		let path = this.router.url.split('/')[1].split('?')[0]

		if (this.router.url.split('/')[2])
			path += `/${this.router.url.split('/')[2]}`

		if (this.router.url.split('?')[1]) {
			const queryParams = this.route.snapshot.params
			localStorage.setItem('redirectQp', JSON.stringify(queryParams))
		}

		localStorage.setItem('redirect', path)

		if (this.facebookApp) {
			this.facebook.signInFacebookApp()
		} else {
			try {
				this.load = true
				this.isLoading.emit(this.load)
				const tokenData: any = await this.facebook.signIn()
				this.signInProcess({
					expiresIn: tokenData.expiresIn,
					token: tokenData.token,
				})
			} catch (err) {
				this.signInProcessError(err)
			}
		}
	}

	signInFacebookApp(expires_in, access_token) {
		if (expires_in && access_token) {
			this.facebook
				.getFacebookUserData(access_token)
				.subscribe((user: any) => {
					this.authRest
						.signinfb({ id: user.id, accessToken: access_token })
						.subscribe((tokenData: any) => {
							try {
								this.load = true
								this.isLoading.emit(this.load)
								this.signInProcess({
									expiresIn: tokenData.expiresIn,
									token: tokenData.token,
								})

								const path = localStorage.getItem('redirect')
								if (path) {
									const queryParams =
										localStorage.getItem('redirectQp')

									let qp
									if (queryParams)
										qp = JSON.parse(queryParams)
									localStorage.removeItem('redirect')
									localStorage.removeItem('redirectQp')

									this.router.navigate([path], {
										queryParams: qp,
									})
								}
							} catch (err) {
								this.signInProcessError(err)
							}
						})
				})
		}
	}

	async submit() {
		this.f.email.markAsDirty()
		if (this.form.valid && this.noEmail && this.userId) {
			try {
				const updatedUser = await this.userRest
					.updateUserEmail(this.userId, this.form.value.email)
					.toPromise()
				this.userRest.currentUser.next(updatedUser)
			} catch (error) {
				this.swalService.fire({
					icon: 'error',
					title: 'Oops...',
					text: error.error,
					confirmButtonColor: '#ED6745',
				})
			}
		}
	}

	private async loginAction() {
		this.facebook.setAuthTokens(this.token, this.expiresIn)
		this.userRest
			.getUserByEmail(this.tokenService.getUserEmail())
			.pipe(take(1))
			.subscribe({
				next: async (res) => {
					if (res !== null) {
						this.userRest.currentUser.next(res)
						this.load = false
						this.isLoading.emit(this.load)
						await firstValueFrom(
							this.userRest.setIsOnline(res.id)
						).catch((error) => {
							console.error(error)
						})
					} else {
						this.load = false
						this.isLoading.emit(this.load)
					}
				},
				error: (error) => {
					console.error('IN FACEBOOKCOMP', error)
					throw error
				},
			})
	}

	async reactiveUser() {
		this.user = await this.userRest.reactivateUser(this.user.id).toPromise()
		this.loginAction()
	}

	isFacebookApp() {
		if (isPlatformBrowser(this.platformId) && navigator) {
			const ua =
				navigator.userAgent || navigator.vendor || window['opera']
			return ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1
		}
		return false
	}

	getFacebookAppInfo() {
		const url = window.location.hash.substring(1)
		if (url && url.length > 0) {
			const facebookInfo = JSON.parse(
				'{"' +
					decodeURI(url)
						.replace(/"/g, '\\"')
						.replace(/&/g, '","')
						.replace(/=/g, '":"') +
					'"}'
			)
			return facebookInfo
		}
		return
	}

	signInProcess({ expiresIn = undefined, token = undefined } = {}) {
		this.expiresIn = expiresIn
		this.token = token
		this.user = this.facebook.getUser(this.token)
		this.userSubmit.emit({ user: this.user, token: this.token })
		// location.reload();
		if (!this.user.isBan) {
			if (this.user.email) {
				if (this.user.email.includes('@facebook.com')) {
					this.swalService
						.fire({
							icon: 'error',
							title: 'Oops..',
							text: this.translate.instant('swal.facebook.email'),
							confirmButtonText: this.translate.instant(
								'swal.facebook.confirmEmail'
							),
						})
						.then(() =>
							this.router.navigate(
								[this.lang + '/wizard/profile'],
								{
									queryParams: { step: 'email' },
								}
							)
						)
				}
			}
			if (!this.user.isActive) {
				this.load = false
				this.isLoading.emit(this.load)
				this.ngxSmartModalService
					.getModal('reactivateProfileModal')
					.open()
			} else {
				this.loginAction()
				/*   if (localStorage.getItem('socketId')) {
					const socketId = localStorage.getItem('socketId')
					const userId = this.user.id
					this.userRest.setSocketId(userId, socketId).toPromise()
				  }*/
			}
		} else {
			// tslint:disable-next-line: max-line-length
			this.swalService.fire({
				icon: 'error',
				title: 'Oops...',
				text: this.translate.instant('swal.facebook.banned'),
				confirmButtonColor: '#ED6745',
			})
			//this.ngxSmartModalService.get('loginModal').close()
		}
	}

	signInProcessError(err) {
		console.error(err)
		this.load = false
		this.isLoading.emit(this.load)
		this.swalService.fire({
			icon: 'error',
			title: 'Oops...',
			text: 'Facebook error',
			confirmButtonColor: '#ED6745',
		})

		this.userRest.currentUser.next(null)
		if (err && err.status === 400 && err.error) {
			this.noEmail = true
			this.userId = err.error
		}
	}
}
