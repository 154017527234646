import { isPlatformBrowser } from '@angular/common'
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'

import { AutoLoginService } from './services/auto-login.service'
import { UserRestService } from './services/rest/user/user-rest.service'
import { DynamicScriptLoaderServiceService } from './services/dynamic-script-loader-service.service'
import { environment } from 'src/environments/environment'
import { TokenService } from 'src/app/services/rest/token/token.service'
import { GoogleAnalyticsService } from './services/google-analytics.service'

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
	public isBrowser: boolean
	public currentUser
	showCookies: boolean = true

	constructor(
		@Inject(PLATFORM_ID) private platformId: object,
		private userRest: UserRestService,
		public router: Router,
		private autologin: AutoLoginService,
		private dynScriptLoader: DynamicScriptLoaderServiceService,
		private tokenService: TokenService,
		private readonly googleAnalyticsService: GoogleAnalyticsService
	) {
		this.isBrowser = isPlatformBrowser(this.platformId)
	}

	async ngOnInit() {
		const token = this.tokenService.getToken()
		if (token && !this.tokenService.isExpiredToken()) {
			await this.autologin.autoLogin(token)
		}
		try {
			if (this.isBrowser) {
				this.dynScriptLoader.loadScript('GoogleMapAPI')
				setTimeout(() => {
					window.addEventListener('storage', (event) => {
						if (event.key === 'logoutEvent') {
							window.location.reload()
						}
					})
					let activateStats = false
					const user = this.userRest.getCurrentUserValue()
					if (!user || (user && !user.isAdmin)) {
						activateStats = true
					}
					if (environment.production && activateStats) {
						this.googleAnalyticsService.initialize()
						this.dynScriptLoader.loadScript('Tawk')
					}
				}, 0) // Delay till after page load
			}

			this.router.events.subscribe((event) => {
				if (event instanceof NavigationEnd) {
					this.showCookies =
						!event.url.startsWith('/publiez-votre-annonce') &&
						!event.url.startsWith('/en/publiez-votre-annonce')
				}
			})
		} catch (error) {
			console.error('APP COMP INIT error ===> ', error)
		}
	}
}
