import { NgModule } from '@angular/core'
import {
	//PreloadAllModules,
	//Router,
	RouterModule,
	Routes,
	UrlMatcher,
	UrlSegment,
} from '@angular/router'
import { AuthGuard } from './guards/auth.guard'

const routes: Routes = [
	{
		path: '',
		pathMatch: 'full',
		loadChildren: () =>
			import('./pages/home/home.module').then((m) => m.HomeModule),
	},
	{
		pathMatch: 'full',
		path: 'search',
		redirectTo: '',
	},
	{
		pathMatch: 'full',
		path: 'recherche',
		redirectTo: '',
	},
	{
		path: 'en/search/:type/:city/:page',
		loadChildren: () =>
			import('./pages/search/search.module').then((m) => m.SearchModule),
	},
	{
		path: 'recherche/:type/:city/:page',
		loadChildren: () =>
			import('./pages/search/search.module').then((m) => m.SearchModule),
	},
	{
		path: 'en/search/:type/:city',
		loadChildren: () =>
			import('./pages/search/search.module').then((m) => m.SearchModule),
	},
	{
		path: 'recherche/:type/:city',
		loadChildren: () =>
			import('./pages/search/search.module').then((m) => m.SearchModule),
	},
	// {
	// 	path: 'search/:city',
	// 	loadChildren: () =>
	// 		import('./pages/search/search.module').then((m) => m.SearchModule),
	// },
	// {
	// 	path: 'recherche/:city',
	// 	loadChildren: () =>
	// 		import('./pages/search/search.module').then((m) => m.SearchModule),
	// },
	{
		path: 'payment',
		loadChildren: () =>
			import('./pages/payment/payment.module').then(
				(m) => m.PaymentModule
			),
		//canActivate: [AuthGuard],
	},
	{
		path: 'colocation',
		loadChildren: () =>
			import('./pages/group/group.module').then((m) => m.GroupModule),
	},
	{
		path: 'list',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./pages/list/list.module').then((m) => m.ListModule),
	},
	{
		path: 'place',
		loadChildren: () =>
			import('./pages/place/place.module').then((m) => m.PlaceModule),
	},
	{
		path: 'places',
		loadChildren: () =>
			import('./pages/places/places.module').then((m) => m.PlacesModule),
	},
	{
		path: 'profile',
		loadChildren: () =>
			import('./pages/profile/profile.module').then(
				(m) => m.ProfileModule
			),
	},
	{
		path: 'certificat',
		loadChildren: () =>
			import('./pages/certificat/certificat.module').then(
				(m) => m.CertificatModule
			),
		//canActivate: [AuthGuard],
	},
	{
		path: 'activation/search',
		loadChildren: () =>
			import('./pages/search-activation/search-activation.module').then(
				(m) => m.SearchActivationModule
			),
	},
	{
		path: 'tarifs',
		loadChildren: () =>
			import('./pages/tarifs/tarifs.module').then((m) => m.TarifsModule),
	},
	{
		path: 'my-premium',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./pages/premium/premium.module').then(
				(m) => m.PremiumModule
			),
	},
	{
		path: 'verifier',
		loadChildren: () =>
			import('./pages/verifier/verifier.module').then(
				(m) => m.VerifierModule
			),
	},
	{
		path: 'messages',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./pages/chatroom/chatroom.module').then(
				(m) => m.ChatroomModule
			),
	},
	{
		path: 'conditions',
		loadChildren: () =>
			import(
				'./pages/conditions-utilisation/conditions-utilisation.module'
			).then((m) => m.ConditionsUtilisationModule),
	},
	{
		path: 'checkout',
		loadChildren: () =>
			import('./pages/checkout/checkout.module').then(
				(m) => m.CheckoutModule
			),
		//canActivate: [AuthGuard],
	},
	{
		path: 'wizard/profile',
		loadChildren: () =>
			import('./pages/profile-wizard/profile-wizard.module').then(
				(m) => m.ProfileWizardModule
			),
	},
	{
		path: 'publiez-votre-annonce',
		loadChildren: () =>
			import('./pages/group-wizard/group-wizard.module').then(
				(m) => m.GroupWizardModule
			),
	},
	{ path: 'wizard', redirectTo: '/publiez-votre-annonce', pathMatch: 'full' },
	{
		path: 'qui-sommes-nous',
		loadChildren: () =>
			import('./pages/qui-sommes-nous/qui-sommes-nous.module').then(
				(m) => m.QuiSommesNousModule
			),
	},
	{
		path: 'pro',
		loadChildren: () =>
			import('./pages/owner/owner.module').then((m) => m.OwnerModule),
	},
	{
		path: 'mon-rendez-vous',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./pages/appointment/appointment.module').then(
				(m) => m.AppointmentModule
			),
	},
	{
		path: 'notifications',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./pages/notifications/notifications.module').then(
				(m) => m.NotificationsModule
			),
	},
	{
		path: 'reservation',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./pages/reservation/reservation.module').then(
				(m) => m.ReservationModule
			),
	},
	{
		path: '404',
		loadChildren: () =>
			import('./pages/not-found/not-found.module').then(
				(m) => m.NotFoundModule
			),
	},

	//Pour l'internationalisation du site
	{
		matcher: createLangUrlMatcher('reservation'),
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./pages/reservation/reservation.module').then(
				(m) => m.ReservationModule
			),
	},
	{
		matcher: createLangUrlMatcher('payment'),
		loadChildren: () =>
			import('./pages/payment/payment.module').then(
				(m) => m.PaymentModule
			),
	},
	{
		matcher: createLangUrlMatcher('wizard/profile'),
		loadChildren: () =>
			import('./pages/profile-wizard/profile-wizard.module').then(
				(m) => m.ProfileWizardModule
			),
	},
	{
		matcher: createLangUrlMatcher('colocation'),
		loadChildren: () =>
			import('./pages/group/group.module').then((m) => m.GroupModule),
	},
	{
		matcher: createLangUrlMatcher('list'),
		loadChildren: () =>
			import('./pages/list/list.module').then((m) => m.ListModule),
	},
	{
		matcher: createLangUrlMatcher('place'),
		loadChildren: () =>
			import('./pages/place/place.module').then((m) => m.PlaceModule),
	},
	{
		matcher: createLangUrlMatcher('places'),
		loadChildren: () =>
			import('./pages/places/places.module').then((m) => m.PlacesModule),
	},
	{
		matcher: createLangUrlMatcher('profile'),
		loadChildren: () =>
			import('./pages/profile/profile.module').then(
				(m) => m.ProfileModule
			),
	},
	{
		matcher: createLangUrlMatcher('certificat'),
		loadChildren: () =>
			import('./pages/certificat/certificat.module').then(
				(m) => m.CertificatModule
			),
		//canActivate: [AuthGuard],
	},
	{
		matcher: createLangUrlMatcher('activation/search'),
		loadChildren: () =>
			import('./pages/search-activation/search-activation.module').then(
				(m) => m.SearchActivationModule
			),
	},
	{
		matcher: createLangUrlMatcher('tarifs'),
		loadChildren: () =>
			import('./pages/tarifs/tarifs.module').then((m) => m.TarifsModule),
	},
	{
		matcher: createLangUrlMatcher('verifier'),
		loadChildren: () =>
			import('./pages/verifier/verifier.module').then(
				(m) => m.VerifierModule
			),
	},
	{
		matcher: createLangUrlMatcher('messages'),
		loadChildren: () =>
			import('./pages/chatroom/chatroom.module').then(
				(m) => m.ChatroomModule
			),
	},
	{
		matcher: createLangUrlMatcher('conditions'),
		loadChildren: () =>
			import(
				'./pages/conditions-utilisation/conditions-utilisation.module'
			).then((m) => m.ConditionsUtilisationModule),
	},

	{
		matcher: createLangUrlMatcher('checkout'),
		loadChildren: () =>
			import('./pages/checkout/checkout.module').then(
				(m) => m.CheckoutModule
			),
		//canActivate: [AuthGuard],
	},

	{
		matcher: createLangUrlMatcher('publiez-votre-annonce'),
		loadChildren: () =>
			import('./pages/group-wizard/group-wizard.module').then(
				(m) => m.GroupWizardModule
			),
	},
	{
		matcher: createLangUrlMatcher('wizard'),
		redirectTo: 'en/publiez-votre-annonce',
		pathMatch: 'full',
	},
	{
		matcher: createLangUrlMatcher('qui-sommes-nous'),
		loadChildren: () =>
			import('./pages/qui-sommes-nous/qui-sommes-nous.module').then(
				(m) => m.QuiSommesNousModule
			),
	},
	{
		matcher: createLangUrlMatcher('pro'),
		loadChildren: () =>
			import('./pages/owner/owner.module').then((m) => m.OwnerModule),
	},
	{
		matcher: createLangUrlMatcher('404'),
		loadChildren: () =>
			import('./pages/not-found/not-found.module').then(
				(m) => m.NotFoundModule
			),
	},
	{
		matcher: createLangUrlMatcher('my-premium'),
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./pages/premium/premium.module').then(
				(m) => m.PremiumModule
			),
	},
	{
		path: 'en',
		pathMatch: 'full',
		loadChildren: () =>
			import('./pages/home/home.module').then((m) => m.HomeModule),
	},
	{
		matcher: createLangUrlMatcher('mon-rendez-vous'),
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./pages/appointment/appointment.module').then(
				(m) => m.AppointmentModule
			),
	},
	{
		matcher: createLangUrlMatcher('notifications'),
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./pages/notifications/notifications.module').then(
				(m) => m.NotificationsModule
			),
	},
	{
		path: '**',
		redirectTo: '/404',
	},
]

function createLangUrlMatcher(path: string): UrlMatcher {
	return (url: UrlSegment[]) => {
		if (url.length === 0) return null
		const langSegment = url[0]
		if (langSegment.path === 'en' || langSegment.path === 'fr') {
			const remainingUrl = url.slice(1)
			const pathMatch = pathMatchByString(remainingUrl, path)
			if (pathMatch) {
				return {
					consumed: [langSegment, ...pathMatch.consumed],
					posParams: { ...pathMatch.posParams, lang: langSegment },
				}
			}
		}
		return null
	}
}

function pathMatchByString(url: UrlSegment[], path: string) {
	const pathSegments = path.split('/')
	let consumed: UrlSegment[] = []
	let posParams: { [key: string]: UrlSegment } = {}

	for (let i = 0; i < pathSegments.length; i++) {
		const segment = pathSegments[i]
		const urlSegment = url[i]

		if (!urlSegment) return null

		if (segment.startsWith(':')) {
			const paramName = segment.substring(1)
			posParams[paramName] = urlSegment
			consumed.push(urlSegment)
		} else {
			if (segment !== urlSegment.path) return null
			consumed.push(urlSegment)
		}
	}

	return { consumed, posParams }
}

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			initialNavigation: 'enabledBlocking',
			onSameUrlNavigation: 'reload',
			useHash: false,
			anchorScrolling: 'enabled',
			scrollPositionRestoration: 'enabled',
		}),
	],
	exports: [RouterModule],
	declarations: [],
})
export class AppRoutingModule {}
