import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core'
import { formatDistanceToNow } from 'date-fns'
import * as fr from 'date-fns/locale/fr/index.js'

@Pipe({
	name: 'timeAgo',
})
export class TimeAgoPipe implements PipeTransform {
	@Inject(LOCALE_ID) public locale: string

	transform(value: string | Date) {
		const date = value instanceof Date ? value : new Date(value)
		const options = {}

		// if (this.locale === "fr-FR")
		options['locale'] = fr
		return formatDistanceToNow(date, options)
	}
}
