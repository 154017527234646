import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { TimeAgoPipe } from './time-ago.pipe'
import { TitleCasePipe } from './titlecase.pipe';
import { LocaleTimezonePipe } from './pipes/locale-timezone-pipe.pipe'

@NgModule({
	declarations: [TimeAgoPipe, TitleCasePipe, LocaleTimezonePipe],
	imports: [CommonModule],
	exports: [TimeAgoPipe, TitleCasePipe,LocaleTimezonePipe],
})
export class SharedPipeModule {}
