<ng-container *ngIf="profileData" style="cursor: pointer;">
  <a (click)="submit(profileData.id)">
    <div class="card h-100">
      <div class="card-img-top">
        <img
          *ngIf="profileData.profilePicture"
          src="{{ profileData.profilePicture }}"
          src-fallback="assets/img/defaultProfilePicture.png"
          class="lazyload"
          alt="profilePicture of group: {{ groupData?.title }}"
        />
        <picture class="w-100 h-100">
          <!-- <source
            srcset="assets/img/defaultProfilePicture.webp"
            type="image/webp"
          />
          <source
            srcset="assets/img/defaultProfilePicture.png"
            type="image/png"
          /> -->
          <img
            *ngIf="!profileData.profilePicture"
            src="assets/img/defaultProfilePicture.png"
            class="lazyload"
            alt="profilePicture of group: {{ groupData?.title }}"
          />
        </picture>
      </div>
      <div class="card-body">{{ profileData.firstName }}</div>
    </div>
  </a>
</ng-container>

<ng-container *ngIf="groupData">
  <a (click)="submit(groupData.id)">
    <div class="card">
      <div class="card-img-top">
        <img
          *ngIf="groupData.photos.length > 0"
          src="{{
            groupData.photos[0].includes('public/')
              ? groupData.photos[0]
              : '/public/groups/' + groupData.id + '/' + groupData.photos[0]
          }}"
          class="lazyload"
          alt="groupPhoto of group: {{ groupData?.title }}"
          src-fallback="assets/img/defaultGroupPicture.png"
        />
        <picture class="w-100 h-100">
          <!-- <source
            srcset="assets/img/defaultGroupPicture.webp"
            type="image/webp"
          />
          <source
            srcset="assets/img/defaultGroupPicture.png"
            type="image/png"
          /> -->
          <img
            *ngIf="groupData.photos.length > 0"
            src="assets/img/defaultGroupPicture.png"
            class="lazyload"
            alt="groupPhoto of group: {{ groupData?.title }}"
          />
        </picture>
      </div>
      <div class="card-body">{{ groupData.title }}</div>
    </div>
  </a>
</ng-container>