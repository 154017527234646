import { DOCUMENT } from '@angular/common'
import { Inject, Injectable } from '@angular/core'

@Injectable({
	providedIn: 'root',
})
export class DynamicStyleLoaderService {
	constructor(@Inject(DOCUMENT) private document: Document) {}
	load(styleName: string) {
		const head = this.document.getElementsByTagName('head')[0]
		const style = this.document.createElement('link')
		style.rel = 'stylesheet'
		style.href = styleName
		head.appendChild(style)
	}
}
