import { NgxSmartModalService } from 'ngx-smart-modal'
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { User } from '@src/app/models/user.model'
import { DynamicStyleLoaderService } from '@src/app/services/dynamic-style-loader.service'

@Component({
	selector: 'app-certif-modal',
	templateUrl: './certif-modal.component.html',
	styleUrls: ['./certif-modal.component.scss'],
})
export class CertifModalComponent implements OnInit {
	@Output() pay: EventEmitter<any> = new EventEmitter<any>()
	@Output() continue: EventEmitter<any> = new EventEmitter<any>()
	@Input() currentUser: User
	constructor(
		private ngxSmartModalService: NgxSmartModalService,
		private dynamicStyle: DynamicStyleLoaderService
	) {}

	ngOnInit(): void {
		this.dynamicStyle.load('ngx-smart-modal.css')
	}

	toPay() {
		//console.log('choice', this.choice)
		this.pay.emit('')
		this.ngxSmartModalService.closeLatestModal()
	}

	toContinue() {
		this.continue.emit()
		this.ngxSmartModalService.closeLatestModal()
	}
}
