import {
	Component,
	HostBinding,
	Inject,
	Input,
	OnInit,
	PLATFORM_ID,
} from '@angular/core'
import { NgxSmartModalService } from 'ngx-smart-modal'
import { User } from '../../../models/user.model'
import { TranslateService } from '@ngx-translate/core'
import { UserRestService } from '../../../services/rest/user/user-rest.service'
import { Router } from '@angular/router'
import { SwalService } from '@src/app/services/swal.service'
import { GoogleAnalyticsService } from '@src/app/services/google-analytics.service'
import { isPlatformBrowser } from '@angular/common'

@Component({
	selector: 'app-profile-card',
	templateUrl: './profile-card.component.html',
	styleUrls: ['./profile-card.component.scss'],
})
export class ProfileCardComponent {
	lang: string = ''

	@Input() profileData: User
	@Input() myProfile: boolean
	@Input() uploader
	//profile: link==1;group: link==2;recherche/highlight: link==3
	@Input() link: number
	@Input() currentUser: User
	@Input() isModal?: boolean = false
	@Input() url?: string = null
	@HostBinding('class.h-100') private fill = true
	public defaultPic = 'assets/img/defaultProfilePicture.png'
	public profilePic: string
	constructor(
		private ngxSmartModalService: NgxSmartModalService,
		private router: Router,
		private userRest: UserRestService,
		public translate: TranslateService,
		private swalService: SwalService,
		public readonly gtm: GoogleAnalyticsService,
		@Inject(PLATFORM_ID) private platformId: object
	) {
		if (isPlatformBrowser(this.platformId)) {
			this.lang =
				localStorage.getItem('lang') === 'fr'
					? ''
					: '/' + localStorage.getItem('lang')
		}
	}

	ngOnChanges() {
		if (this.profileData) {
			this.profilePic = this.profileData?.profilePicture
		}
	}

	openModal() {
		if (this.currentUser) {
			this.ngxSmartModalService.getModal('proposeOffreModal').open()
		} else {
			this.ngxSmartModalService.getModal('loginModal').open()
			//this.router.navigate([`/login/${this.router.url}`])
		}
	}

	checkPremiumPhone() {
		if (!this.currentUser?.isPremium) {
			this.swalService.fire({
				icon: 'error',
				title: 'Oops ...',
				text: this.translate.instant('swal.cards.profile.askPremium'),
				confirmButtonText: this.translate.instant(
					'swal.cards.profile.becomePremium'
				),
				confirmButtonColor: '#ED6745',
				preConfirm: () => {
					this.router.navigate(['/' + this.lang + `/tarifs/`], {
						queryParams: { status: 'profile' },
					})
					this.gtm.event('click', 'toPremium', 'profileCard')
				},
			})
		} else {
			this.swalService.fire({
				icon: 'question',
				title: this.translate.instant('swal.cards.profile.validation'),
				text: this.translate.instant(
					'swal.cards.profile.askValidation'
				),
				showCancelButton: true,
				confirmButtonText: this.translate.instant(
					'swal.cards.profile.yes'
				),
				confirmButtonColor: '#ED6745',
				cancelButtonText: this.translate.instant(
					'swal.cards.profile.no'
				),
				preConfirm: async () => {
					const user = await this.userRest
						.setSeenPhoneNumber(
							this.currentUser.id,
							this.profileData.id
						)
						.toPromise()
					this.userRest.currentUser.next(user)

					const result = await this.userRest
						.getPhoneNumber(this.profileData.id)
						.toPromise()
					if (result.error) this.profileData.phone = null
					// console.log({ result })
					this.profileData.phone = result.phone

					// this.userRest.currentUser.next(result)
				},
			})
		}
	}

	goToProfile(profileId) {
		if (profileId) {
			this.router.navigate(['/' + this.lang + `/profile/${profileId}`])
		}
	}
}
