import { NgModule } from '@angular/core'
import { AppModule } from './app.module'
import { AppComponent } from './app.component'
import { TransferHttpCacheModule } from '@nguniversal/common'
import { I18nBrowserModule } from './i18n/i18n.browser.module'

@NgModule({
	imports: [I18nBrowserModule, AppModule, TransferHttpCacheModule],
	bootstrap: [AppComponent],
})
export class AppBrowserModule {}
