import { Inject, NgModule, PLATFORM_ID } from '@angular/core'
import { HttpClient, HttpClientModule } from '@angular/common/http'
import {
	TranslateLoader,
	TranslateModule,
	TranslateService,
} from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import {
	StateKey,
	TransferState,
	makeStateKey,
} from '@angular/platform-browser'
import { Observable, lastValueFrom } from 'rxjs'
import { isPlatformBrowser } from '@angular/common'

@NgModule({
	declarations: [],
	imports: [
		HttpClientModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: translateBrowserLoaderFactory,
				deps: [HttpClient, TransferState],
			},
		}),
	],
})
export class I18nBrowserModule {
	constructor(
		private translateService: TranslateService,
		private transferState: TransferState,
		@Inject(PLATFORM_ID) private platformId: object
	) {
		const key: StateKey<string> = makeStateKey<string>('language')
		const lang = this.transferState.get(key, null)
		if (isPlatformBrowser(platformId)) {
			localStorage.setItem('lang', lang)
		}
		lastValueFrom(this.translateService.use(lang))
	}
}

export class TranslateBrowserLoader implements TranslateLoader {
	constructor(
		private http: HttpClient,
		private transferState: TransferState
	) {}

	public getTranslation(lang: string): Observable<any> {
		const key: StateKey<number> = makeStateKey<number>(
			'transfer-translate-' + lang
		)
		const data = this.transferState.get(key, null)

		// First we are looking for the translations in transfer-state,
		// if none found, http load as fallback
		if (data) {
			return new Observable((observer) => {
				observer.next(data)
				observer.complete()
			})
		} else {
			return new TranslateHttpLoader(this.http).getTranslation(lang)
		}
	}
}

export function translateBrowserLoaderFactory(
	httpClient: HttpClient,
	transferState: TransferState
) {
	return new TranslateBrowserLoader(httpClient, transferState)
}
