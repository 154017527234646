import { Injectable } from '@angular/core'
import {
	ActivatedRouteSnapshot,
	CanActivate,
	CanActivateChild,
	RouterStateSnapshot,
	Router,
} from '@angular/router'

import { AuthRestService } from '@src/app/services/rest/auth/auth-rest.service'

@Injectable({
	providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
	constructor(private auth: AuthRestService, private router: Router) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean {
		if (!this.checkLogin()) {
			this.router.navigateByUrl('')
			return false
		}
		return true
	}

	canActivateChild(
		childRoute: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean {
		return this.canActivate(childRoute, state)
	}

	checkLogin(): boolean {
		return this.auth.isLoggedIn()
	}
}
