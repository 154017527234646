<div class="card h-100" *ngIf="profileData">
	<div class="card-img-top">
		<div style="margin-top: -300px" *ngIf="profileData?.isCertif">
			<div
				class="bg-success position-absolute mx-auto btn btn-circle rounded-circle p-0"
				style="width: 60px; height: 60px; z-index: 1"
			>
				<i class="bi bi-person-check-fill text-white fs-1"></i>
				<div id="logo" class="d-flex justify-content-center">
					<span
						class="badge rounded-pill bg-white text-success fw-bold"
						translate
						>card.profile.verified</span
					>
				</div>
			</div>
		</div>
		<div style="margin-top: -300px" *ngIf="isModal === true">
			<div
				class="bg-success position-absolute mx-auto btn btn-circle rounded-circle"
				style="width: 120px; height: 120px; z-index: 1"
			>
				<i
					class="bi bi-person-check-fill text-white mt-1 display-3 position-relative"
					[style]="{ left: '0.2rem' }"
				></i>
				<h5 class="d-flex justify-content-center">
					<span
						class="badge rounded-pill bg-white text-success fw-bold"
						translate
						>card.profile.verified</span
					>
				</h5>
			</div>
		</div>
		<a
			(click)="goToProfile(profileData.id)"
			*ngIf="link != 1"
			class="h-100 w-100"
		>
			<div
				class="profilePicture"
				class="overflow-hidden w-100 h-100"
				*ngIf="profileData"
			>
				<div *ngIf="profileData.profilePicture" class="w-100 h-100">
					<img
						class="lazyload w-100 h-100"
						[defaultImage]="defaultPic"
						ngSrc="{{ profileData.profilePicture }}"
						width="25"
						height="20"
						alt="photo of {{ profileData.firstName }}"
						src-fallback="assets/img/defaultProfilePicture.png"
					/>
				</div>

				<div *ngIf="!profileData.profilePicture" class="w-100 h-100">
					<img
						ngSrc="{{ 'assets/img/defaultProfilePicture.png' }}"
						width="25"
						height="20"
						class="lazyload w-100 h-100"
						alt="defaultProfilePicture"
						src-fallback="assets/img/defaultProfilePicture.png"
					/>
				</div>
			</div>

			<div class="profilePicture" *ngIf="!profileData">
				<picture class="w-100 h-100">
					<img
						width="25"
						height="20"
						ngSrc="assets/img/findTogether.png"
						alt="Picture appartoo guarant"
						class="w-100 h-100 lazyload"
					/>
				</picture>
			</div>
		</a>
		<div *ngIf="link === 1">
			<div
				class="profilePicture"
				class="overflow-hidden w-100 h-100"
				*ngIf="profileData"
			>
				<div *ngIf="profileData.profilePicture" class="w-100 h-100">
					<img
						class="lazyload w-100 h-100"
						[defaultImage]="defaultPic"
						src="profilePic"
						alt="photo of {{ profileData.firstName }}"
						src-fallback="assets/img/defaultProfilePicture.png"
					/>
				</div>

				<div *ngIf="!profileData.profilePicture" class="w-100 h-100">
					<img
						ngSrc="{{ 'assets/img/defaultProfilePicture.png' }}"
						width="25"
						height="20"
						class="lazyload w-100 h-100"
						alt="defaultProfilePicture"
						src-fallback="assets/img/defaultProfilePicture.png"
					/>
				</div>
			</div>

			<div class="profilePicture" *ngIf="!profileData">
				<picture class="w-100 h-100">
					<!-- <source srcset="assets/img/findTogether.webp" type="image/webp" />
          <source srcset="assets/img/findTogether.png" type="image/png" />
         -->
					<img
						ngSrc="assets/img/findTogether.png"
						width="25"
						height="20"
						alt="Picture appartoo guarant"
						class="w-100 h-100 lazyload"
					/>
				</picture>
			</div>
		</div>
	</div>
	<div class="card-body">
		<div class="card-title m-0">
			<div class="one-line-title" *ngIf="!url">
				<h5>
					{{ profileData?.firstName | titlecase
					}}{{
						profileData?.age !== undefined &&
						profileData?.age !== null &&
						profileData?.age > 0
							? ', ' + profileData?.age + ' ans'
							: ''
					}}
				</h5>
			</div>
			<div class="one-line-title" *ngIf="url">
				<h5
					[translateParams]="{
						name: (profileData?.firstName | titlecase)
					}"
					translate
				>
					profile.description.partner
				</h5>
				<h5>
					{{
						profileData?.age !== undefined &&
						profileData?.age !== null &&
						profileData?.age > 0
							? ', ' + profileData?.age + ' ans'
							: ''
					}}
				</h5>
			</div>
			<h5 class="m-0">
				<ng-container *ngIf="profileData?.relationshipStatus">
					<ng-container
						*ngIf="
							profileData.relationshipStatus === 'inRelationship'
						"
					>
						<p class="badge rounded-pill bg-success" translate>
							<i class="bi bi-people-fill"></i>
							profile.description.relationShip.couple
						</p>
					</ng-container>
					<ng-container
						*ngIf="profileData.relationshipStatus === 'single'"
					>
						<p class="badge rounded-pill bg-success" translate>
							<i class="bi bi-person-fill"></i>
							profile.description.relationShip.single
						</p>
					</ng-container>
				</ng-container>
				<ng-container *ngIf="profileData?.gender">
					<p
						class="badge rounded-pill bg-success m-1"
						*ngIf="profileData.gender === 'male'"
						translate
					>
						<i class="bi bi-gender-male"></i>
						profile.description.gender.male
					</p>
					<p
						class="badge rounded-pill bg-success m-1"
						*ngIf="profileData.gender === 'female'"
						translate
					>
						<i class="bi bi-gender-female"></i>
						profile.description.gender.female
					</p>
				</ng-container>
				<ng-container *ngIf="profileData?.badges">
					<p
						class="badge rounded-pill bg-success m-1"
						*ngIf="profileData.badges.isSmoker"
						translate
					>
						<i class="bi bi-fire"></i>
						profile.badges.isSmoker
					</p>
					<p
						class="badge rounded-pill bg-success m-1"
						*ngIf="profileData.badges.isCook"
						translate
					>
						<i class="bi bi-gender-cookie"></i>
						profile.badges.isCook
					</p>
					<p
						class="badge rounded-pill bg-success m-1"
						*ngIf="profileData.badges.isMusician"
						translate
					>
						<i class="bi bi-music-note-beamed"></i>
						profile.badges.isMusician
					</p>
					<p
						class="badge rounded-pill bg-success m-1"
						*ngIf="profileData.badges.isSpendthrift"
						translate
					>
						<i class="bi bi-basket2-fill"></i>
						profile.badges.isSpendthrift
					</p>
					<p
						class="badge rounded-pill bg-success m-1"
						*ngIf="profileData.badges.isPartyGoer"
						translate
					>
						<i class="bi bi-cup-straw"></i>
						profile.badges.isPartyGoer
					</p>
					<p
						class="badge rounded-pill bg-success m-1"
						*ngIf="profileData.badges.isLayabout"
						translate
					>
						<i class="bi bi-house-heart"></i>
						profile.badges.isLayabout
					</p>
					<p
						class="badge rounded-pill bg-success m-1"
						*ngIf="profileData.badges.isGeek"
						translate
					>
						<i class="bi bi-laptop"></i>
						profile.badges.isGeek
					</p>
					<p
						class="badge rounded-pill bg-success m-1"
						*ngIf="profileData.badges.isTraveller"
						translate
					>
						<i class="bi bi-airplane-fill"></i>
						profile.badges.isTraveller
					</p>
					<p
						class="badge rounded-pill bg-success m-1"
						*ngIf="profileData.badges.isGenerous"
						translate
					>
						<i class="bi bi-cash-coin"></i>
						profile.badges.isGenerous
					</p>
					<p
						class="badge rounded-pill bg-success m-1"
						*ngIf="profileData.badges.isAnimalFriend"
						translate
					>
						<i class="bi bi-discord"></i>
						profile.badges.isAnimalFriend
					</p>
					<p
						class="badge rounded-pill bg-success m-1"
						*ngIf="profileData.badges.isMessy"
						translate
					>
						<i class="bi bi-trash"></i>
						profile.badges.isMessy
					</p>
					<p
						class="badge rounded-pill bg-success m-1"
						*ngIf="profileData.badges.isManiac"
						translate
					>
						<i class="bi bi-brush"></i>
						profile.badges.isManiac
					</p>
				</ng-container>
			</h5>
		</div>
		<div class="card-text" *ngIf="link === 3">
			<div class="m-0">
				<p translate>
					{{
						'profile.search.address'
							| translate
								: {
										firstName:
											profileData?.firstName | titlecase,
										address: profileData?.address
								  }
					}}
				</p>
			</div>
			<div class="row">
				<div class="col-2">
					<i class="bi bi-cash-coin text-muted mt-2 me-3 fs-3"></i>
				</div>
				<div class="col-10" style="margin-top: 12px">
					<p class="text-muted m-0" translate>
						{{
							'profile.search.maxBudget'
								| translate : { budget: profileData?.budget }
						}}
					</p>
				</div>
			</div>
		</div>
		<ng-container *ngIf="link === 2">
			<div
				*ngIf="
					profileData.lastConnection && !profileData.isOnline && !url
				"
				class="last-connection one-line-title"
			>
				<i class="bi bi-plug"></i>
				<span
					translate
					[translateParams]="{
						time: profileData.lastConnection | timeAgo
					}"
					>profile.card.lastConnection</span
				>
			</div>

			<div
				*ngIf="profileData.isOnline"
				class="last-connection one-line-title"
			>
				<i class="bi bi-plug"></i>
				<span translate>profile.card.online</span>
			</div>
			<div
				*ngIf="profileData?.hasPhone && profileData?.sharePhone"
				class="last-connection one-line-title mt-1"
			>
				<i class="bi bi-phone"></i>
				<span
					*ngIf="
						!currentUser ||
						!currentUser.seenPhoneNumber?.includes(profileData.id)
					"
					role="button"
					(click)="checkPremiumPhone()"
					translate
					>profile.card.seePhone</span
				>
				<span
					*ngIf="
						currentUser &&
						currentUser.seenPhoneNumber?.includes(profileData.id)
					"
				>
					{{ profileData?.phone }}</span
				>
			</div>
		</ng-container>
		<!--
    <div class="row" *ngIf="!myProfile && link == 3 && profileData">
      <ng-container
        *ngFor="let situation of profileData.situations | slice: 0:3"
      >
        <ng-container *ngIf="situation.places.length">
          <div class="col-md-4">
            <img
              class="img-fluid"
              [src]="situation.places[0].logo"
              alt="{{ situation.places[0].name }}"
            />
          </div>
        </ng-container>
        <ng-container *ngIf="!situation.places.length">
          <div class="col-md-4">
            <img
              class="img-fluid"
              src="https://cdn1.iconfinder.com/data/icons/linkedin-ui-colored/48/JD-05-2-128.png"
              alt="{{ situation.status }}"
            />
          </div>
        </ng-container>
      </ng-container>
    </div> -->
		<ng-content></ng-content>
		<button
			*ngIf="!myProfile && link === 1"
			class="btn btn-lg btn-primary text-white d-block w-100"
			(click)="openModal()"
		>
			<span translate>profile.card.propose</span>
			{{ profileData?.firstName | titlecase }}
		</button>
		<a
			*ngIf="!myProfile && link === 3 && !isModal"
			class="btn btn-lg btn-primary text-white d-block w-100 mt-2"
			routerLink="{{ lang }}/profile/{{ profileData.id }}"
		>
			<span translate>profile.card.contact</span>
			{{ profileData?.firstName | titlecase }}
		</a>
	</div>
</div>
