import { Component, Input } from '@angular/core'
import { ImgFallbackModule } from 'ngx-img-fallback'

@Component({
	selector: 'app-round-image',
	standalone: true,
	imports: [ImgFallbackModule],
	templateUrl: './round-image.component.html',
	styleUrls: ['./round-image.component.scss'],
})
export class RoundImageComponent {
	@Input() src: string
	@Input() alt: string
	@Input() size?: string
	public defaultPic = 'assets/img/defaultProfilePicture.png'
}
