<ngx-smart-modal
  #certifModal
  [identifier]="'certifModal'"
  [closable]="true"
  customClass="modal-lg"
>
  <div class="modal-header">
    <p class="modal-title text h3" translate>modals.user.certif.title</p>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-6">
        <!-- <h4 contenteditable="true"> -->
        <p translate>
          modals.user.certif.desc1
          <strong translate>modals.user.certif.desc2</strong> modals.user.certif.desc3
          <strong translate>modals.user.certif.desc4</strong> modals.user.certif.desc5
          <strong translate>modals.user.certif.desc6</strong> modals.user.certif.desc7
        </p>
        <table class="table table-striped">
          <caption>Offer infos</caption>
          <thead>
            <tr>
              <th scope="col" class="fw-normal" translate>
                modals.user.certif.line1
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row" class="fw-light" translate>
                modals.user.certif.line2
              </th>
            </tr>
            <tr>
              <th scope="row" class="fw-light" translate>
                modals.user.certif.line3
              </th>
            </tr>
            <tr>
              <th scope="row" class="fw-light" translate>
                modals.user.certif.line4
              </th>
            </tr>
            <tr>
              <th scope="row" class="fw-light" translate>
                modals.user.certif.line5
              </th>
            </tr>
          </tbody>
        </table>

        <div
          class="alert alert-success text justify-content-center text"
          role="alert"
          translate
        >
          modals.user.certif.desc8 <strong translate>modals.user.certif.desc9</strong> modals.user.certif.desc10
        </div>
      </div>
      <div class="col-md-6">
        <!-- <img
          src="assets/img/adorable-persona.jpg"
          alt="oops"
          class="img-fluid"
        />-->
        <app-profile-card
          [link]="3"
          [profileData]="currentUser"
          [isModal]="true"
        >
        </app-profile-card>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-lg btn-light"
      data-bs-dismiss="modal"
      (click)="toContinue()"
      translate
    >
      modals.group.booster.later
    </button>
    <button type="button" class="btn btn-lg btn-success" (click)="toPay()" translate>
      modals.group.booster.now
    </button>
  </div>
</ngx-smart-modal>
