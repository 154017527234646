import { isPlatformBrowser } from '@angular/common'
import { Inject, Injectable, PLATFORM_ID } from '@angular/core'
import { JwtHelperService } from '@auth0/angular-jwt'

@Injectable({
	providedIn: 'root',
})
export class TokenService {
	isBrowser: boolean = false
	constructor(
		private jwtHelperService: JwtHelperService,
		@Inject(PLATFORM_ID) private platformId: object
	) {
		this.isBrowser = isPlatformBrowser(this.platformId)
	}

	public getToken(): string {
		try {
			if (this.isBrowser) {
				return localStorage.getItem('id_token') || null
			}
			return null
		} catch (error) {
			console.error('AUTOLOGIN ===>', error)
			throw error
		}
	}

	public getUserByToken(): any {
		const token = this.getToken()

		try {
			if (!token) return null
			const user = this.jwtHelperService.decodeToken(token)
			return user
		} catch (e) {
			console.error(e)
			return null
		}
	}

	public setToken(token): void {
		if (this.isBrowser) {
			localStorage.setItem('id_token', token)
		}
	}

	public getUserId(): string {
		const user = this.getUserByToken()
		if (user) {
			return user.id
		}
		return null
	}

	public getUserEmail(): string {
		const user = this.getUserByToken()
		if (user) {
			return user.email
		}
		return null
	}

	public isExpiredToken(): boolean {
		return this.jwtHelperService.isTokenExpired(this.getToken())
	}
}
