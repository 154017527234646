import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { NgxSmartModalModule, NgxSmartModalService } from 'ngx-smart-modal'
import { ProposeOffreModalComponent } from './propose-offre-modal/propose-offre-modal.component'
import { ReactivateProfileModalComponent } from './reactivate-profile-modal/reactivate-profile-modal.component'
import { ReportProfileModalComponent } from './report-profile-modal/report-profile-modal.component'
import { CardsModule } from '@src/app/components/cards/cards.module'
import { TranslateModule } from '@ngx-translate/core'

@NgModule({
	declarations: [
		ReactivateProfileModalComponent,
		ReportProfileModalComponent,
		ProposeOffreModalComponent,
	],
	exports: [
		ReactivateProfileModalComponent,
		ReportProfileModalComponent,
		ProposeOffreModalComponent,
	],
	imports: [CommonModule, NgxSmartModalModule, CardsModule, TranslateModule],
	providers: [NgxSmartModalService],
})
export class ModalProfileModule {}
