<ng-container *ngIf="room">
	<div class="card h-100" *ngIf="edit">
		<div class="card-img-top" *ngIf="!room.isFree && room.user">
			<div
				class="profilePicture"
				class="overflow-hidden w-100 h-100"
				*ngIf="room.user"
			>
				<div *ngIf="room.user.profilePicture" class="w-100 h-100">
					<img
						class="lazyload w-100 h-100"
						src="{{ room.user.profilePicture }}"
						alt="photo of {{ room.user.firstName }}"
						src-fallback="assets/img/defaultProfilePicture.png"
					/>
				</div>

				<div *ngIf="!room.user.profilePicture" class="w-100 h-100">
					<img
						src="{{ 'assets/img/defaultProfilePicture.png' }}"
						class="lazyload w-100 h-100"
						alt="defaultProfilePicture"
						src-fallback="assets/img/defaultProfilePicture.png"
					/>
				</div>
			</div>

			<div class="profilePicture" *ngIf="!room.user">
				<picture class="w-100 h-100">
					<!--
          <source srcset="assets/img/findTogether.webp" type="image/webp" />
          <source srcset="assets/img/findTogether.png" type="image/png" /> -->
					<img
						src="assets/img/findTogether.png"
						alt="Picture appartoo guarant"
						class="w-100 h-100 lazyload"
					/>
				</picture>
			</div>
		</div>
		<div class="card-img-top" *ngIf="!room.isFree && !room.user">
			<picture class="w-100 h-100">
				<!-- <source
          srcset="assets/img/defaultProfilePicture.webp"
          type="image/webp"
        />
        <source
          srcset="assets/img/defaultProfilePicture.png"
          type="image/png"
        /> -->
				<img
					src="assets/img/defaultProfilePicture.png"
					class="w-100 h-100 lazyload"
					alt="defaultProfilePicture"
				/>
			</picture>
		</div>
		<div class="card-img-top" *ngIf="room.isFree">
			<i class="bi bi-house-heart fs-1"></i>
		</div>
		<div class="card-body">
			<div class="card-title" *ngIf="room.isFree" translate>
				card.room.free
			</div>
			<div
				class="card-title"
				*ngIf="!room.isFree && !room.user"
				translate
			>
				card.room.notFree
			</div>
			<ng-container *ngIf="!room.isFree && room.user">
				<div class="card-title" *ngIf="room.user.firstName">
					{{
						'card.room.owner'
							| translate : { owner: room.user.firstName }
					}}
				</div>
				<div
					class="card-title"
					*ngIf="!room.user.firstName && room.user.email"
				>
					{{
						'card.room.owner'
							| translate : { owner: room.user.email }
					}}
				</div>
			</ng-container>
			<div class="card-text"></div>
			<div>
				<button
					*ngIf="room.isFree"
					class="btn btn-lg btn-success d-block w-100"
					(click)="selectRoom()"
					translate
				>
					<i class="bi bi-plus" aria-hidden="true"></i>
					card.room.add
				</button>
				<button
					*ngIf="allowDelete && roomsLeft > 1"
					class="btn btn-lg btn-danger d-block w-100"
					(click)="deleteRoom()"
					translate
				>
					<i class="bi bi-x" aria-hidden="true"></i>
					card.room.delete
				</button>
			</div>
		</div>
	</div>

	<div class="card" *ngIf="!edit">
		<div class="card-img-top" *ngIf="!room.isFree && room.user">
			<a
				routerLink="{{ lang }}/profile/{{ room.user._id }}"
				class="w-100"
			>
				<div
					class="profilePicture"
					class="overflow-hidden w-100 h-100"
					*ngIf="room.user"
				>
					<div *ngIf="room.user.profilePicture" class="w-100 h-100">
						<img
							class="lazyload w-100 h-100"
							src="{{ room.user.profilePicture }}"
							alt="photo of {{ room.user.firstName }}"
							src-fallback="assets/img/defaultProfilePicture.png"
						/>
					</div>

					<div *ngIf="!room.user.profilePicture" class="w-100 h-100">
						<img
							src="{{ 'assets/img/defaultProfilePicture.png' }}"
							class="lazyload w-100 h-100"
							alt="defaultProfilePicture"
							src-fallback="assets/img/defaultProfilePicture.png"
						/>
					</div>
				</div>

				<div class="profilePicture" *ngIf="!room.user">
					<picture class="w-100 h-100">
						<!-- <source srcset="assets/img/findTogether.webp" type="image/webp" />
            <source srcset="assets/img/findTogether.png" type="image/png" /> -->
						<img
							src="assets/img/findTogether.png"
							alt="Picture appartoo guarant"
							class="w-100 h-100 lazyload"
						/>
					</picture>
				</div>
			</a>
		</div>
		<div class="card-img-top" *ngIf="!room.isFree && !room.user">
			<picture class="w-100 h-100">
				<!--  <source
          srcset="assets/img/defaultProfilePicture.webp"
          type="image/webp"
        />
        <source
          srcset="assets/img/defaultProfilePicture.png"
          type="image/png"
        /> -->
				<img
					src="assets/img/defaultProfilePicture.png"
					class="w-100 h-100 lazyload"
					alt="defaultProfilePicture"
				/>
			</picture>
		</div>
		<div class="card-img-top" *ngIf="room.isFree">
			<i class="bi bi-house-heart fs-1"></i>
		</div>
		<div class="card-body">
			<div class="card-title" *ngIf="room.isFree" translate>
				card.room.free
			</div>
			<div
				class="card-title"
				*ngIf="!room.isFree && !room.user"
				translate
			>
				card.room.notFree
			</div>
			<div class="card-title" *ngIf="!room.isFree && room.user">
				{{
					'card.room.owner'
						| translate : { owner: room.user.firstName }
				}}
			</div>
		</div>
	</div>
</ng-container>
